import styles from './prizes-page.module.css';

import { memo } from 'react';
import { PrizePool } from 'components/common';
import { PrizesPageHowItWorks } from './how-it-works/prizes-page-how-it-works';
import { PrizesPageFaq } from './faq/prizes-page-how-it-works-faq';

const PrizesPage = memo(() => {
  return (
    <div className={styles.main}>
      <PrizePool />

      <PrizesPageHowItWorks />

      <PrizesPageFaq />
    </div>
  );
});

export default PrizesPage;
