import styles from './leaderboard-page.module.css';

import { memo } from 'react';
import { useIntl } from 'react-intl';
import { ProvideLeaderboardPageStore } from './leaderboard-page-store-provider';
import { LeaderboardPageList } from './list/leaderboard-page-list';
import { LeaderboardPageTabs } from './tabs/leaderboard-page-tabs';
import { LeaderboardPageStats } from './stats/leaderboard-page-stats';

const LeaderboardPage = memo(() => {
  const intl = useIntl();

  return (
    <ProvideLeaderboardPageStore>
      <div className={styles.main}>
        <h1 className={styles.title}>
          {intl.formatMessage({
            id: 'page.leaderboard.title',
            defaultMessage: 'Leaderboard',
          })}
        </h1>

        <LeaderboardPageTabs />
        <LeaderboardPageStats />
        <LeaderboardPageList />
      </div>
    </ProvideLeaderboardPageStore>
  );
});

export default LeaderboardPage;
