import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { telegramAppColors } from 'styles/telegram-styles';

const SESSION_STORAGE_KEY = 'tgSession';

export class TelegramStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _initDataStr: string | null = null;
  private _initDataRefCode: string | null = null;

  get initDataStr() {
    return this._initDataStr;
  }

  get initDataRefCode() {
    return this._initDataRefCode;
  }

  init() {
    const telegramApp = window.Telegram.WebApp;

    telegramApp.enableClosingConfirmation();
    telegramApp.setHeaderColor(telegramAppColors.bg);
    telegramApp.setBackgroundColor(telegramAppColors.bg);
    telegramApp.expand();

    this.setAndSubscribeOnSessionIdChange();

    runInAction(() => {
      this._initDataStr = telegramApp.initData ?? null;
      this._initDataRefCode = telegramApp.initDataUnsafe?.start_param ?? null;
    });
  }

  ready() {
    window.Telegram.WebApp.ready();
  }

  openLink(url: string) {
    if (url.includes('t.me') || url.includes('tg://')) {
      window.Telegram.WebApp.openTelegramLink(url);
      return;
    }

    window.Telegram.WebApp.openLink(url, {
      try_instant_view: true,
    });
  }

  openLinkViaVenomWallet(link: string) {
    this.openLink(`${CONFIG.venomWallet.linkGenerator}${link}`);
  }

  private setAndSubscribeOnSessionIdChange() {
    const telegramApp = window.Telegram.WebApp;
    const currentSessionId = telegramApp.initDataUnsafe.hash;

    if (!currentSessionId) {
      return;
    }

    localStorage.setItem(SESSION_STORAGE_KEY, currentSessionId);

    window.addEventListener('storage', (event) => {
      if (
        event.storageArea !== localStorage ||
        event.key !== SESSION_STORAGE_KEY ||
        event.newValue === currentSessionId
      ) {
        return;
      }

      telegramApp.close();
    });
  }
}
