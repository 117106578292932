import { memo, useMemo } from 'react';
import { ProvideBaseStores } from 'providers/base-stores-provider';
import { AppMain } from './app-main';
import { AppConnectWallet } from './app-connect-wallet';
import { AppRoutes } from 'routes';

const App = memo(() => {
  const loadConnectWalletApp = useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    return window.location.pathname.includes(AppRoutes.connectWallet.fullPath);
  }, []);

  if (loadConnectWalletApp) {
    return <AppConnectWallet />;
  }

  return (
    <ProvideBaseStores>
      <AppMain />
    </ProvideBaseStores>
  );
});

export default App;
