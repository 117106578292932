import styles from './leaderboard-page-stats.module.css';

import { observer } from 'mobx-react-lite';
import { useLeaderboardPageStore } from '../leaderboard-page-store-provider';
import { formatExp } from 'utils/format';
import { useIntl } from 'react-intl';
import { XpLabel } from 'components/common';

export const LeaderboardPageStats = observer(() => {
  const intl = useIntl();
  const storePage = useLeaderboardPageStore();

  if (!storePage.currentStageStats) {
    return null;
  }

  return (
    <div className={styles.main}>
      <h1>
        {intl.formatMessage({
          id: 'page.leaderboard.stats.title',
          defaultMessage: 'Stats',
        })}
      </h1>

      <div className={styles.total_value}>
        <span>{storePage.currentStageStats.totalUsers}</span>

        <div className={styles.total_value_label}>
          {intl.formatMessage({
            id: 'page.leaderboard.stats.users',
            defaultMessage: 'Users',
          })}
        </div>
      </div>

      <div className={styles.total_value}>
        <span className={styles.total_xp}>
          {formatExp(storePage.currentStageStats.totalXp)}
        </span>

        <div className={styles.total_value_label}>
          {intl.formatMessage(
            {
              id: 'page.leaderboard.stats.total_xp',
              defaultMessage: 'Total {xp} Generated',
            },
            { xp: <XpLabel size='sm' /> }
          )}
        </div>
      </div>
    </div>
  );
});
