export const AppRoutes = {
  index: {
    path: '/',
    fullPath: '/',
  },
  leaderboard: {
    path: '/leaderboard',
    fullPath: '/leaderboard',
  },
  go: {
    path: '/go',
    fullPath: '/go',
  },
  friends: {
    path: '/friends',
    fullPath: '/friends',
  },
  prizes: {
    path: '/prizes',
    fullPath: '/prizes',
  },
  connectWallet: {
    path: '/connect-wallet',
    fullPath: '/connect-wallet',
  },
} as const;
