import styles from './menu.module.css';
import classNames from 'classnames';

import { memo, useMemo } from 'react';
import { AppRoutes } from 'routes';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { ReactComponent as TasksSvg } from 'images/icons/menu/tasks.svg';
import { ReactComponent as LeaderboardSvg } from 'images/icons/menu/leaderboard.svg';
import { ReactComponent as GoSvg } from 'images/icons/menu/go.svg';
import { ReactComponent as GoHighlightedSvg } from 'images/icons/menu/go-highlighted.svg';
import { ReactComponent as FriendsSvg } from 'images/icons/menu/friends.svg';
import { ReactComponent as PrizesSvg } from 'images/icons/menu/prizes.svg';

export const LayoutMenu = memo(() => {
  const intl = useIntl();

  const menuData = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'menu.tasks', defaultMessage: 'Tasks' }),
        icon: <TasksSvg />,
        link: AppRoutes.index.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.leaderboard',
          defaultMessage: 'Leaderboard',
        }),
        icon: <LeaderboardSvg />,
        link: AppRoutes.leaderboard.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.go',
          defaultMessage: 'GO!',
        }),
        icon: <GoSvg />,
        iconHighlighted: <GoHighlightedSvg />,
        link: AppRoutes.go.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.friends',
          defaultMessage: 'Friends',
        }),
        icon: <FriendsSvg />,
        link: AppRoutes.friends.fullPath,
      },
      {
        text: intl.formatMessage({
          id: 'menu.prizes',
          defaultMessage: 'Prizes',
        }),
        icon: <PrizesSvg />,
        link: AppRoutes.prizes.fullPath,
      },
    ],
    [intl]
  );

  return (
    <div className={styles.main}>
      <div className={styles.menu}>
        {menuData.map((x) => (
          <NavLink
            key={x.link}
            to={x.link}
            className={({ isActive }) =>
              classNames(
                styles.menu_item,
                {
                  [styles.menu_item_highlighted]:
                    !!x.iconHighlighted && !isActive,
                },
                {
                  [styles.menu_item_active]: isActive,
                }
              )
            }
          >
            {({ isActive }) => (
              <>
                {!!x.iconHighlighted && !isActive ? x.iconHighlighted : x.icon}

                <span>{x.text}</span>
              </>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
});
