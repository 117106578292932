import styles from './leaderboard-page-tabs.module.css';

import { observer } from 'mobx-react-lite';
import { useLeaderboardPageStore } from '../leaderboard-page-store-provider';
import { Tabs } from 'components/common/tabs/tabs';
import { LeaderboardType } from '../leaderboard-page-store';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const LeaderboardPageTabs = observer(() => {
  const intl = useIntl();

  const pageStore = useLeaderboardPageStore();

  const overallTabInfo = useMemo(
    () => ({
      id: -1,
      text: intl.formatMessage({
        id: 'page.leaderboard.stages.tab.overall',
        defaultMessage: 'Overall',
      }),
    }),
    [intl]
  );

  const typeTabs: { id: LeaderboardType; text: string }[] = useMemo(
    () => [
      {
        id: LeaderboardType.Main,
        text: intl.formatMessage({
          id: 'page.leaderboard.type.main',
          defaultMessage: 'Main',
        }),
      },
      {
        id: LeaderboardType.Social,
        text: intl.formatMessage({
          id: 'page.leaderboard.type.social',
          defaultMessage: 'Social Tasks',
        }),
      },
    ],
    [intl]
  );

  const stageTabs = useMemo(
    () => [
      ...pageStore.stages.map((x) => ({
        id: x.id,
        text: intl.formatMessage(
          {
            id: 'page.leaderboard.stages.tab',
            defaultMessage: 'Stage {index}',
          },
          { index: x.stageIndex }
        ),
      })),
      overallTabInfo,
    ],
    [intl, pageStore.stages, overallTabInfo]
  );

  return (
    <div className={styles.main}>
      <div className={styles.leaderboard_type_tabs}>
        <Tabs
          data={typeTabs}
          activeTabId={pageStore.leaderboardType}
          onTabClick={(tab) =>
            pageStore.setLeaderboardType(tab.id as LeaderboardType)
          }
          variant='highlighted'
        />
      </div>

      <div className={styles.leaderboard_stages}>
        <Tabs
          data={stageTabs}
          activeTabId={pageStore.currentStage?.id ?? overallTabInfo.id}
          onTabClick={(tab) => pageStore.setCurrentStage(Number(tab.id))}
        />
      </div>
    </div>
  );
});
