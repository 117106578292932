import styles from './init-error.module.css';

import { CONFIG } from 'config';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { CopyInitDataButton } from '.';
import { LayoutBody, LayoutPage } from '..';

import errorSvg from 'images/maintenance/error.svg';

export const LayoutInitError = memo(({ error }: { error: string }) => {
  const intl = useIntl();

  return (
    <LayoutBody>
      <LayoutPage>
        <div className={styles.main}>
          <img src={errorSvg} width={487} height={480} alt='' />

          <div className={styles.description}>
            {intl.formatMessage({
              id: 'page.init_error.description',
              defaultMessage:
                'The error occurred during application initialization. Please try again later.',
            })}

            {CONFIG.env !== 'PROD' && (
              <>
                <div className={styles.error}>({error})</div>

                <CopyInitDataButton />
              </>
            )}
          </div>
        </div>
      </LayoutPage>
    </LayoutBody>
  );
});
