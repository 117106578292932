import styles from './main-page.module.css';

import { memo } from 'react';
import { ProvideMainPageStore } from './main-page-store-provider';
import { MainPageHeader } from './header/main-page-header';
import { MainPageStages } from './stages/main-page-stages';
import { MainPageTaskList } from './list/main-page-task-list';
import { MainPageStageInfo } from './stage-info/main-page-stage-info';

const MainPage = memo(() => {
  return (
    <ProvideMainPageStore>
      <div className={styles.main}>
        <MainPageHeader />
        <MainPageStages />
        <MainPageStageInfo />
        <MainPageTaskList />
      </div>
    </ProvideMainPageStore>
  );
});

export default MainPage;
