import { makeAutoObservable, runInAction } from 'mobx';
import { getSkip } from 'utils/pagination';

type FetchResult<T> = {
  items: T[];
};

type ConstructorProps<T> = {
  fetchFn: (params: { take: number; skip: number }) => Promise<FetchResult<T>>;
  itemsPerPage: number;
};
export class Collection<T extends { id: string }> {
  constructor(private props: ConstructorProps<T>) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _items: T[] | null = null;
  private _loading: boolean = false;
  private _canLoadMore: boolean = false;
  private _currentPage: number = 0;

  get canLoadMore() {
    return this._canLoadMore;
  }

  get loading() {
    return this._loading;
  }

  get items() {
    return this._items;
  }

  async fetchData() {
    await this.fetch({ reset: true });
  }

  async refresh() {
    if (!this.items) {
      return this.fetchMore();
    }

    await this.fetch({ refresh: true });
  }

  async fetchMore() {
    await this.fetch({});
  }

  reset() {
    runInAction(() => {
      this._loading = false;
      this._canLoadMore = false;
      this._items = null;
      this._currentPage = 0;
    });
  }

  private async fetch(props: { refresh?: boolean; reset?: boolean }) {
    try {
      runInAction(() => {
        this._loading = true;
      });

      const { fetchFn, itemsPerPage } = this.props;
      const { refresh, reset } = props;

      let newPage = this._currentPage + 1;
      let skip = getSkip(newPage, itemsPerPage);
      let take = itemsPerPage;

      if (refresh) {
        newPage = this._currentPage;
        skip = 0;
        take = itemsPerPage * newPage;
      }

      if (reset) {
        newPage = 1;
        skip = 0;
        take = itemsPerPage;
      }

      const result = await fetchFn({
        skip: skip,
        take: take,
      });

      let currentItems = this.items ?? [];

      if (props?.refresh || props?.reset) {
        currentItems = [];
      }

      const items = currentItems.concat(result.items);
      const canLoadMore = result.items.length >= itemsPerPage;

      runInAction(() => {
        this._currentPage = newPage;
        this._items = items;
        this._canLoadMore = canLoadMore;
        this._loading = false;
      });
    } catch (err) {
      console.error(err);

      runInAction(() => {
        this._loading = false;
      });
    }
  }
}
