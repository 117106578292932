import styles from './main-page-task-list.module.css';

import { Task } from 'components/common';
import { useMainPageStore } from '../main-page-store-provider';
import { observer } from 'mobx-react-lite';

export const MainPageTaskList = observer(() => {
  const mainPageStore = useMainPageStore();

  return (
    <div className={styles.main}>
      {mainPageStore.currentSelectedStageTasks.map((task) => (
        <Task key={task.id} task={task} />
      ))}
    </div>
  );
});
