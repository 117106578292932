import { AccountType, VenomNetworkApi } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { TelegramStore } from './telegram-store';

type ApiUserInfo = {
  uuid: string;
  account: string | null;
  wallet: string | null;
};

export class AuthStore {
  constructor(
    private telegramStore: TelegramStore,
    private venomNetworkApi: VenomNetworkApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _userData: ApiUserInfo | null = null;
  private _accessToken: string | null = null;

  get userData() {
    return this._userData;
  }

  get accessToken() {
    return this._accessToken;
  }

  async init() {
    await this.login();
  }

  async login() {
    if (!this.telegramStore.initDataStr) {
      throw new Error('Login failed - Telegram init data was not provided.');
    }

    const result = await this.venomNetworkApi.auth.login({
      type: 'tgMiniapp',
      checkString: this.telegramStore.initDataStr,
      refererCode: this.telegramStore.initDataRefCode,
    });

    const accessToken = result.data.access;

    this.venomNetworkApi.setSecurityData(accessToken);

    const userResult = await this.venomNetworkApi.info.postUserInfo();

    runInAction(() => {
      this._accessToken = accessToken;
      this._userData = {
        uuid: userResult.data.uuid,
        wallet:
          userResult.data.accounts.find(
            (x) => x.accountType === AccountType.Wallet
          )?.accountId ?? null,
        account:
          userResult.data.accounts.find((x) => x.accountType === AccountType.Tg)
            ?.accountName ?? null,
      };
    });
  }
}
