import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import styles from './xp-label.module.css';

import { memo } from 'react';
import { useIntl } from 'react-intl';
import { formatExp } from 'utils/format';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value?: BigNumber;
};

export const XpLabel = memo(({ size, value }: Props) => {
  const intl = useIntl();

  return (
    <div
      className={classNames(
        styles.main,
        { [styles.xs]: size === 'xs' },
        { [styles.sm]: size === 'sm' },
        { [styles.md]: size === 'md' || !size },
        { [styles.lg]: size === 'lg' }
      )}
    >
      {!!value && (
        <div className={styles.value}>{formatExp(value ?? BigNumber(0))}</div>
      )}

      <div className={styles.label}>
        {intl.formatMessage({ id: 'common.xp', defaultMessage: 'XP' })}
      </div>
    </div>
  );
});
