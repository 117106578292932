import styles from './friends-page-tabs.module.css';

import { observer } from 'mobx-react-lite';
import { useFriendsPageStore } from '../friends-page-store-provider';
import { Tabs } from 'components/common/tabs/tabs';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FriendsTabType } from '../friends-page-store';

export const FriendsPageTabs = observer(() => {
  const intl = useIntl();

  const store = useFriendsPageStore();

  const tabsData: { id: FriendsTabType; text: string }[] = useMemo(
    () => [
      {
        id: FriendsTabType.L1,
        text: intl.formatMessage({
          id: 'page.friends.type.l1',
          defaultMessage: 'L1',
        }),
      },
      {
        id: FriendsTabType.L2,
        text: intl.formatMessage({
          id: 'page.friends.type.l2',
          defaultMessage: 'L2',
        }),
      },
      {
        id: FriendsTabType.ALL,
        text: intl.formatMessage({
          id: 'page.friends.type.all',
          defaultMessage: 'All',
        }),
      },
    ],
    [intl]
  );

  return (
    <div className={styles.main}>
      <div className={styles.friends_type_tabs}>
        <Tabs
          data={tabsData}
          activeTabId={store.currentTabType}
          onTabClick={(tab) => store.setCurrentTab(tab.id as FriendsTabType)}
        />
      </div>
    </div>
  );
});
