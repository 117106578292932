import { IntlShape } from 'react-intl';

export const getGeneralRules = (intl: IntlShape) => [
  {
    text: intl.formatMessage({
      id: 'page.prizes.how_it_works.general_rules.rule1',
      defaultMessage:
        'It is necessary to complete more than 13 tasks per season (there will be more than 40 in total)',
    }),
  },
  {
    text: intl.formatMessage({
      id: 'page.prizes.how_it_works.general_rules.rule2',
      defaultMessage:
        'Owners of the "Early Participant Mainnet NFT" are provided with an additional experience multiplier of x1.5',
    }),
  },
  {
    text: intl.formatMessage({
      id: 'page.prizes.how_it_works.general_rules.rule3',
      defaultMessage:
        'Users who originally qualified for the "Early Participant Mainnet NFT" will earn an extra XP boost applied at the end of the season. If you traded or transferred the NFT, you will not be eligible for the XP boost',
    }),
  },
  {
    text: intl.formatMessage({
      id: 'page.prizes.how_it_works.general_rules.rule4',
      defaultMessage:
        'The use of multi-accounting is not encouraged. Corresponding measures may be taken',
    }),
  },
  {
    text: intl.formatMessage({
      id: 'page.prizes.how_it_works.general_rules.rule5',
      defaultMessage:
        'We will only take into account the users that checked in on venom.network at least once.',
    }),
  },
];
