import { stageConfig } from './stage';
import { prodConfig } from './prod';

const currentConfig =
  process.env.REACT_APP_ENV === 'PROD' ? prodConfig : stageConfig;

export const CONFIG = {
  env: process.env.REACT_APP_ENV,
  ...currentConfig,
};
