import { MainPageStore } from './main-page-store';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useBaseStores } from 'providers/base-stores-provider';
import { createContext, useContext, useEffect } from 'react';

const storeContext = createContext<MainPageStore>({} as MainPageStore);

type Props = {
  children?: React.ReactNode;
};
export const ProvideMainPageStore = observer(({ children }: Props) => {
  const { userStore, venomNetworkApi } = useBaseStores();

  const store = useLocalObservable(
    () => new MainPageStore(userStore, venomNetworkApi)
  );

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.initialized) {
    return null;
  }

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useMainPageStore = () => {
  return useContext(storeContext);
};
