import styles from './spinner.module.css';

import { memo } from 'react';
import { FaCircleNotch } from 'react-icons/fa6';

export const Spinner = memo(() => {
  return (
    <div className={styles.main}>
      <FaCircleNotch />
    </div>
  );
});
