import { VenomNetworkApi } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { TaskModel, TaskType } from 'models';
import { TelegramStore, UserStore } from 'stores';

const AFTER_CHECK_TASK_COOLDOWN = 30;
const BEFORE_CHECK_TASK_COOLDOWN = 15;

export class TaskStore {
  constructor(
    private taskModel: TaskModel,
    private telegramStore: TelegramStore,
    private userStore: UserStore,
    private venomNetworkApi: VenomNetworkApi
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _expandInfoSection: boolean = false;
  private _isCheckTaskInProgress: boolean = false;
  private _hasUserInteractedWithTask: boolean = false;

  private _cooldownInterval: NodeJS.Timeout | null = null;
  private _cooldownSeconds: number = 0;

  get expandInfoSection() {
    return this._expandInfoSection;
  }

  get isTaskCompleted() {
    return this.userStore.isTaskCompleted(this.taskModel.id);
  }

  get isTaskInteractable() {
    if (this.isTaskCompleted) {
      return false;
    }

    return (
      this.taskModel.type === TaskType.Checkable ||
      this.taskModel.type === TaskType.CreateTwitterPost
    );
  }

  get isCooldownInProgress() {
    return !!this._cooldownInterval;
  }

  get isCheckTaskInProgress() {
    return this._isCheckTaskInProgress;
  }

  get cooldownSeconds() {
    return this._cooldownSeconds;
  }

  get canCheckTask() {
    return this._hasUserInteractedWithTask && !this.isCooldownInProgress;
  }

  get highlightTask() {
    return this.taskModel.isBlue && !this.isTaskCompleted;
  }

  toggleInfoSection() {
    runInAction(() => {
      this._expandInfoSection = !this._expandInfoSection;
    });
  }

  async checkTask() {
    if (!this.isTaskInteractable) {
      return;
    }

    try {
      runInAction(() => {
        this._isCheckTaskInProgress = true;
      });

      await this.venomNetworkApi.info.postSocialCheck({
        taskCode: this.taskModel.code,
      });

      await this.userStore.refresh();
    } catch (err) {
      console.error(err);
    } finally {
      this.startCooldown(AFTER_CHECK_TASK_COOLDOWN);

      runInAction(() => {
        this._isCheckTaskInProgress = false;
      });
    }
  }

  async openTaskPrimaryLink() {
    if (!this.isTaskInteractable) {
      return;
    }

    let link = this.taskModel.primaryLink;

    if (this.taskModel.type === TaskType.CreateTwitterPost) {
      const socialTwitterResult =
        await this.venomNetworkApi.info.postSocialTwitterText({
          taskId: this.taskModel.id,
        });
      const encodedText = encodeURIComponent(socialTwitterResult.data.text);

      link = `https://x.com/intent/tweet?text=${encodedText}`;
    }

    if (!link) {
      return;
    }

    this.openLink(link);
  }

  openLink(link: string) {
    this.startCooldown(BEFORE_CHECK_TASK_COOLDOWN);

    runInAction(() => {
      this._hasUserInteractedWithTask = true;
    });

    this.telegramStore.openLink(link);
  }

  private startCooldown(seconds: number) {
    clearInterval(this._cooldownInterval ?? undefined);

    const interval = setInterval(() => {
      runInAction(() => {
        this._cooldownSeconds -= 1;
      });

      if (this._cooldownSeconds > 0) {
        return;
      }

      clearInterval(interval);

      runInAction(() => {
        this._cooldownInterval = null;
      });
    }, 1000);

    runInAction(() => {
      this._cooldownInterval = interval;
      this._cooldownSeconds = seconds;
    });
  }
}
