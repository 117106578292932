import styles from './external-link.module.css';

import { useBaseStores } from 'providers/base-stores-provider';
import { memo } from 'react';
import { FaArrowUp } from 'react-icons/fa6';

export const ExternalLink = memo(
  ({ url, text }: { url: string; text: string }) => {
    const { telegramStore } = useBaseStores();
    return (
      <button
        className={styles.main}
        onClick={() => telegramStore.openLink(url)}
      >
        <span>{text}</span>

        <FaArrowUp />
      </button>
    );
  }
);
