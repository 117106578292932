import BigNumber from 'bignumber.js';

import { PostReferralsListResponseElementDto } from 'api';

export class ReferralModel {
  constructor(dto: PostReferralsListResponseElementDto) {
    this.id = dto.refAddr;
    this.exp = BigNumber(dto.xpGained);
    this.level = dto.refLvl.toUpperCase();
  }

  id: string;
  exp: BigNumber;
  level: string;
}
