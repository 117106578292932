import ConnectWalletPage from 'modules/connect-wallet/connect-wallet-page';

import { memo } from 'react';
import { LayoutBody, LayoutPage } from './layout';

export const AppConnectWallet = memo(() => {
  return (
    <LayoutBody>
      <LayoutPage>
        <ConnectWalletPage />
      </LayoutPage>
    </LayoutBody>
  );
});
