import BigNumber from 'bignumber.js';

export class StageStatsModel {
  constructor(dto: {
    totalSocialUser: number;
    totalUser: number;
    totalXp: string;
    totalXpSocial: string;
  }) {
    this.totalUsers = dto.totalUser;
    this.totalXp = BigNumber(dto.totalXp);

    this.totalSocialUsers = dto.totalSocialUser;
    this.totalSocialXp = BigNumber(dto.totalXpSocial);
  }

  totalUsers: number;
  totalXp: BigNumber;

  totalSocialUsers: number;
  totalSocialXp: BigNumber;
}
