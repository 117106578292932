import styles from './copy-init-data-button.module.css';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CONFIG } from 'config';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/base-stores-provider';

export const CopyInitDataButton = observer(() => {
  const intl = useIntl();

  const { telegramStore } = useBaseStores();

  if (!telegramStore.initDataStr || CONFIG.env === 'PROD') {
    return null;
  }

  return (
    <CopyToClipboard text={telegramStore.initDataStr}>
      <div className={styles.copy_data}>
        {intl.formatMessage({
          id: 'page.init_error.copy_data',
          defaultMessage: 'Copy Init Data',
        })}
      </div>
    </CopyToClipboard>
  );
});
