import styles from './tabs.module.css';
import classNames from 'classnames';

import { memo } from 'react';

type TabInfo = {
  id: string | number;
  text: string;
};

type Props = {
  data: TabInfo[];
  activeTabId?: string | number;
  onTabClick?: (tab: TabInfo) => void;
  variant?: 'highlighted';
};

export const Tabs = memo(
  ({ data, activeTabId, onTabClick, variant }: Props) => {
    return (
      <div
        className={classNames(styles.main, {
          [styles.highlighted]: variant === 'highlighted',
        })}
      >
        {data.map((x) => (
          <button
            key={x.id}
            className={classNames(styles.tab, {
              [styles.tab_active]: x.id === activeTabId,
            })}
            onClick={() => onTabClick?.(x)}
          >
            <span>{x.text}</span>
          </button>
        ))}
      </div>
    );
  }
);
