import dayjs from 'dayjs';
import styles from './stage-timer.module.css';

import { memo, useCallback, useEffect, useState } from 'react';

type Props = {
  finishDate: number;
  onFinish?: () => void;
};

export const StageTimer = memo(({ finishDate, onFinish }: Props) => {
  const [currentDate, setCurrentDate] = useState(dayjs().unix());

  const formatDiffStr = useCallback(
    (currentDate: number) => {
      const dateEnd = dayjs.unix(finishDate);
      const dateNow = dayjs.unix(currentDate);

      const diff = dateEnd.diff(dateNow);

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      if (days <= 0) {
        return `${hours}h : ${minutes}m : ${seconds}s`;
      }

      return `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
    },
    [finishDate]
  );

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = dayjs().unix();

      if (now >= finishDate) {
        clearInterval(timerInterval);
        onFinish?.();
      }

      setCurrentDate(Math.min(finishDate, now));
    }, 900);

    return () => {
      clearInterval(timerInterval);
    };
  }, [finishDate, onFinish]);

  return <div className={styles.main}>{formatDiffStr(currentDate)}</div>;
});
