import styles from './friends-page-stats.module.css';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';

import { CONFIG } from 'config';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BlueTaskIcon, XpLabel } from 'components/common';
import { useFriendsPageStore } from '../friends-page-store-provider';
import { ReactComponent as ReferalLinkSvg } from 'images/icons/referral-link.svg';
import { ReactComponent as ReferalCheckSvg } from 'images/icons/referral-check.svg';

const COPIED_TEXT_SECONDS = 3;

export const FriendsPageStats = observer(() => {
  const intl = useIntl();

  const store = useFriendsPageStore();

  const [linkCopied, setLinkCopied] = useState(false);

  const onLinkCopied = useCallback(() => {
    setLinkCopied(true);
  }, []);

  const refLink = useMemo(() => {
    return `${CONFIG.telegramBotApp.url}?startapp=${store.referralsStats?.refCode}`;
  }, [store.referralsStats?.refCode]);

  useEffect(() => {
    if (!linkCopied) {
      return;
    }

    const timeout = setTimeout(() => {
      setLinkCopied(false);
    }, COPIED_TEXT_SECONDS * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [linkCopied]);

  return (
    <div className={styles.main_container}>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'page.friends.stats.title',
          defaultMessage: 'Invite friends',
        })}
      </h2>

      <h5 className={styles.title_description}>
        {intl.formatMessage(
          {
            id: 'page.friends.stats.description',
            defaultMessage:
              'Only friends who have completed at least one {blueIcon} task are counted',
          },
          { blueIcon: <BlueTaskIcon size='sm' /> }
        )}
      </h5>

      <CopyToClipboard text={refLink} onCopy={onLinkCopied}>
        <button className={styles.link_button}>
          <span>
            {!linkCopied
              ? intl.formatMessage({
                  id: 'page.friends.stats.link',
                  defaultMessage: 'Your invitation link',
                })
              : intl.formatMessage({
                  id: 'page.friends.stats.link.copied',
                  defaultMessage: 'Link copied',
                })}
          </span>

          {linkCopied ? <ReferalCheckSvg /> : <ReferalLinkSvg />}
        </button>
      </CopyToClipboard>

      <h4 className={styles.xp_description}>
        {intl.formatMessage(
          {
            id: 'page.friends.stats.xp_description',
            defaultMessage:
              'You get {l1ExpValue} reward when a 1st line (L1) friends get XP, and {l2ExpValue} from 2nd line (L2) friends',
          },
          { l1ExpValue: <span>5% XP</span>, l2ExpValue: <span>1% XP</span> }
        )}
      </h4>

      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'page.friends.stats.table.title',
          defaultMessage: 'Total',
        })}
      </h2>

      <div className={styles.total_table}>
        <div className={classNames(styles.total_table_row, styles.row_header)}>
          <div>
            {intl.formatMessage({
              id: 'page.friends.stats.table.level',
              defaultMessage: 'Level',
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'page.friends.stats.table.count',
              defaultMessage: 'Count',
            })}
          </div>
          <div>
            {intl.formatMessage({
              id: 'page.friends.stats.table.rewarded_xp',
              defaultMessage: 'Rewarded XP',
            })}
          </div>
        </div>

        {store.referralsStats?.allLevels.map((level) => (
          <div key={level.level} className={styles.total_table_row}>
            <div className={styles.level_label}>
              <div>{level.level}</div>
            </div>
            <div>{level.count}</div>
            <div>
              <XpLabel size='xs' value={level.xpGained} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
