import BigNumber from 'bignumber.js';

const defaultBNFormatting = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  suffix: '',
};

export const formatBigNumber = (number: BigNumber, decimalPlaces: number) => {
  return number
    .dp(decimalPlaces, BigNumber.ROUND_DOWN)
    .toFormat(defaultBNFormatting);
};

export const formatExp = (exp: BigNumber) => {
  return formatBigNumber(exp, 0);
};

export const cutAddress = (str: string) => cutString(str, 6, 4);

export const cutString = (str: string, takeFirst: number, takeLast: number) => {
  if (!str || str.length <= takeFirst + takeLast) {
    return str;
  }

  return (
    str.substring(0, takeFirst) +
    '...' +
    str.substring(str.length - takeLast, str.length)
  );
};
