import styles from './case-image.module.css';
import classNames from 'classnames';

import commonCasePng from 'images/cases/common.png';
import commonCaseOpenPng from 'images/cases/common-open.png';
import uncommonCasePng from 'images/cases/uncommon.png';
import uncommonCaseOpenPng from 'images/cases/uncommon-open.png';
import rareCasePng from 'images/cases/rare.png';
import rareCaseOpenPng from 'images/cases/rare-open.png';
import epicCasePng from 'images/cases/epic.png';
import epicCaseOpenPng from 'images/cases/epic-open.png';
import legendaryCasePng from 'images/cases/legendary.png';
import legendaryCaseOpenPng from 'images/cases/legendary-open.png';
import magicCasePng from 'images/cases/magic.png';
import magicCaseOpenPng from 'images/cases/magic-open.png';
import testnetCasePng from 'images/cases/testnet.png';
import testnetCaseOpenPng from 'images/cases/testnet-open.png';

import { memo, useMemo } from 'react';

type Props = {
  className?: string;
  type:
    | 'common'
    | 'uncommon'
    | 'rare'
    | 'epic'
    | 'legendary'
    | 'magic'
    | 'testnet';
  open?: boolean;
};

export const CaseImage = memo(({ className, type, open }: Props) => {
  const imgSrc = useMemo(() => {
    switch (type) {
      case 'common':
        return !open ? commonCasePng : commonCaseOpenPng;
      case 'uncommon':
        return !open ? uncommonCasePng : uncommonCaseOpenPng;
      case 'rare':
        return !open ? rareCasePng : rareCaseOpenPng;
      case 'epic':
        return !open ? epicCasePng : epicCaseOpenPng;
      case 'legendary':
        return !open ? legendaryCasePng : legendaryCaseOpenPng;
      case 'magic':
        return !open ? magicCasePng : magicCaseOpenPng;
      case 'testnet':
        return !open ? testnetCasePng : testnetCaseOpenPng;
      default:
        return null;
    }
  }, [open, type]);

  if (!imgSrc) {
    return null;
  }

  return (
    <img
      className={classNames(styles.main, className)}
      src={imgSrc}
      alt={type}
      loading='lazy'
    />
  );
});
