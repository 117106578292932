/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountData {
  accountId: string;
  accountName?: string | null;
  accountType: AccountType;
}

export enum AccountType {
  Tg = 'tg',
  Twitter = 'twitter',
  Wallet = 'wallet',
}

export type AuthDataDTO =
  | (TgMiniappLoginRequest & {
      type: 'tgMiniapp';
    })
  | (TgLoginRequest & {
      type: 'tg';
    })
  | (WalletLoginRequest & {
      type: 'wallet';
    });

export enum BindAccountType {
  Wallet = 'wallet',
  Tg = 'tg',
  Twitter = 'twitter',
}

export interface GetInfoResponseDto {
  stages: StageInfo[];
  tasks: TaskInfo[];
}

export interface GetSocialTwitterLinkResponseDto {
  link: string;
}

export interface GetUserModifiersResponseDto {
  full?: UserModifiers | null;
  half?: UserModifiers | null;
}

export interface GetUserRewardClaimInfo {
  claimed: boolean;
  reward: string;
  /** @format int32 */
  rewardId: number;
  rewardType?: string | null;
  userId: string;
}

export interface GetUserRewardClaimResponseDto {
  info: GetUserRewardClaimInfo[];
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  socialRank?: number | null;
  /** @format BigDecimal */
  socialXp: string | null;
  /** @format BigDecimal */
  xp: string | null;
}

export interface GetUserRewardStakeResponseDto {
  /** @format BigDecimal */
  bonusXp: string | null;
  /** @format BigDecimal */
  calculatedStakeAmount: string | null;
  calculatedType?: string | null;
  expectedType: string;
  finalized: boolean;
  /** @format BigDecimal */
  modifier: string | null;
  /** @format int64 */
  rank?: number | null;
  /** @format int32 */
  season: number;
}

export interface LoginResponseDTO {
  access: string;
  refresh: string;
}

export interface PaginatedRequest {
  /** @format int64 */
  skip: number;
  /** @format int64 */
  take?: number | null;
}

export interface PostInfoPayload {
  data: string[];
}

export interface PostLeaderboardRequestDto {
  onlyWithNft: boolean;
  pagination: PaginatedRequest;
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage?: number | null;
}

export interface PostLeaderboardResponseDto {
  data: PostLeaderboardResponseElementDto[];
  /** @format int64 */
  limit: number;
  /** @format int64 */
  offset: number;
}

export interface PostLeaderboardResponseElementDto {
  /** @format BigDecimal */
  exp: string;
  nft: boolean;
  /** @format int64 */
  rank: number;
  user: string;
  wallet: string;
}

export interface PostReferralsListRequestDto {
  lvl?: RefLevel | null;
  pagination: PaginatedRequest;
  /** @format int32 */
  season?: number | null;
  sorting: ReferralsListSortedRequest;
}

export interface PostReferralsListResponseDto {
  data: PostReferralsListResponseElementDto[];
  /** @format int64 */
  limit: number;
  /** @format int64 */
  offset: number;
}

export interface PostReferralsListResponseElementDto {
  /** @format int64 */
  lastActivity?: number | null;
  refAddr: string;
  refLvl: RefLevel;
  /** @format BigDecimal */
  xpGained: string;
}

export interface PostSocialCheckRequestDto {
  taskCode: string;
}

export interface PostSocialLeaderboardRequestDto {
  pagination: PaginatedRequest;
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage?: number | null;
}

export interface PostSocialLeaderboardResponseDto {
  data: PostSocialLeaderboardResponseElementDto[];
  /** @format int64 */
  limit: number;
  /** @format int64 */
  offset: number;
}

export interface PostSocialLeaderboardResponseElementDto {
  account: string;
  /** @format BigDecimal */
  exp: string;
  /** @format int64 */
  rank: number;
  user: string;
}

export interface PostSocialTelegramAuthRequestDto {
  authDate: string;
  firstName?: string | null;
  hash: string;
  id: string;
  lastName?: string | null;
  photoUrl?: string | null;
  username?: string | null;
}

export interface PostSocialTelegramAuthResponseDto {
  firstName?: string | null;
  lastName?: string | null;
  photoUrl?: string | null;
  username?: string | null;
}

export interface PostSocialTwitterBindRequestDto {
  oauthToken: string;
  oauthVerifier: string;
}

export interface PostSocialTwitterBindResponseDto {
  access: string;
  name: string;
}

export interface PostSocialTwitterTextRequestDto {
  /** @format int32 */
  taskId: number;
}

export interface PostSocialTwitterTextResponseDto {
  text: string;
}

export interface PostStatsGeneralRequestDto {
  pagination: PaginatedRequest;
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage?: number | null;
}

export interface PostStatsGeneralResponseDto {
  data: PostStatsGeneralResponseElementDto[];
  /** @format int64 */
  limit: number;
  /** @format int64 */
  offset: number;
  total: PostStatsGeneralResponseTotalElementDto;
}

export interface PostStatsGeneralResponseElementDto {
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage?: number | null;
  /** @format int64 */
  totalSocialUser: number;
  /** @format int64 */
  totalUser: number;
  /** @format BigDecimal */
  totalXp: string;
  /** @format BigDecimal */
  totalXpSocial: string;
}

export interface PostStatsGeneralResponseTotalElementDto {
  /** @format int64 */
  socialUserCount: number;
  /** @format BigDecimal */
  totalXp: string;
  /** @format BigDecimal */
  totalXpSocial: string;
  /** @format int64 */
  userCount: number;
}

export type PostUserBindRequestDto =
  | (TgBindRequest & {
      type: 'tg';
    })
  | (WalletBindRequest & {
      type: 'wallet';
    })
  | (TwitterBindRequest & {
      type: 'twitter';
    });

export interface PostUserHistoryRequestDto {
  pagination: PaginatedRequest;
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage?: number | null;
}

export interface PostUserHistoryResponseDto {
  data: PostUserHistoryResponseElementDto[];
  /** @format int64 */
  limit: number;
  /** @format int64 */
  offset: number;
}

export interface PostUserHistoryResponseElementDto {
  /** @format BigDecimal */
  bonusMultiplier: string | null;
  /** @format BigDecimal */
  exp: string;
  /** @format BigDecimal */
  expModified: string;
  nft: boolean;
  /** @format BigDecimal */
  nftMultiplier: string | null;
  /** @format BigDecimal */
  resultMultiplier: string | null;
  /** @format int32 */
  season: number;
  /** @format int32 */
  stage: number;
  /** @format int32 */
  taskId: number;
}

export interface PostUserInfoResponseDto {
  accounts: AccountData[];
  uuid: string;
}

export interface PostUserRewardClaimInfo {
  data: string;
  reward: string;
  /** @format int32 */
  rewardId: number;
  signature: string;
  userId: string;
}

export interface PostUserRewardClaimResponseDto {
  info: PostUserRewardClaimInfo[];
}

export interface PostUserRewardTextRequestDto {
  /** @format int32 */
  season: number;
}

export interface PostUserRewardTextResponseDto {
  text: string;
}

export interface PostUserStatsRequestDto {
  /** @format int32 */
  season: number;
}

export interface PostUserStatsResponseDto {
  stages: PostUserStatsStageResponseElementDto[];
  tasks: number[];
  total?: PostUserStatsTotalResponseElementDto | null;
}

export interface PostUserStatsStageResponseElementDto {
  /** @format BigDecimal */
  bonusMultiplier: string | null;
  /** @format BigDecimal */
  exp: string;
  nft?: boolean | null;
  /** @format BigDecimal */
  nftMultiplier: string | null;
  /** @format int64 */
  rank?: number | null;
  /** @format BigDecimal */
  resultMultiplier: string | null;
  /** @format int32 */
  season?: number | null;
  /** @format BigDecimal */
  socialExp: string;
  /** @format int64 */
  socialRank?: number | null;
  /** @format int32 */
  stage?: number | null;
}

export interface PostUserStatsTotalResponseElementDto {
  /** @format BigDecimal */
  exp: string;
  nft?: boolean | null;
  /** @format int64 */
  rank?: number | null;
  /** @format BigDecimal */
  socialExp: string;
  /** @format int64 */
  socialRank?: number | null;
}

export enum RefLevel {
  L1 = 'l1',
  L2 = 'l2',
}

export interface ReferralLevelInfo {
  lvl: RefLevel;
  /** @format int64 */
  referralsCnt: number;
  /** @format BigDecimal */
  xpGained: string;
}

export enum ReferralsListSortField {
  XP = 'xP',
  LastActivity = 'lastActivity',
}

export interface ReferralsListSortedRequest {
  asc?: boolean | null;
  field: ReferralsListSortField;
}

export interface RefreshRequestDto {
  refreshToken: string;
}

export interface RefreshResponseDto {
  access: string;
  refresh: string;
}

export interface StageInfo {
  description: string;
  /** @format int64 */
  endDate: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  stage: number;
  stakingCoefficients: StakingCoefficientData[];
  /** @format int64 */
  startDate: number;
  /** @format int32 */
  tasksToComplete?: number | null;
}

export interface StakingCoefficientData {
  /** @format double */
  coefficient: number;
  /** @format int64 */
  cutOff: number;
}

export interface TaskInfo {
  code: string;
  description: string;
  /** @format BigDecimal */
  firstXp: string;
  /** @format int32 */
  id: number;
  info?: string | null;
  isBlue: boolean;
  isShown: boolean;
  isSocial: boolean;
  links: TaskLinkData[];
  logoUrl?: string | null;
  name: string;
  /** @format BigDecimal */
  nextXp: string;
  /** @format int32 */
  ord: number;
  repeatable: boolean;
  /** @format int32 */
  stageId: number;
  videoUrl?: string | null;
}

export interface TaskLinkData {
  link: string;
  linkType: string;
}

export interface TgBindRequest {
  authDate: string;
  firstName?: string | null;
  hash: string;
  id: string;
  lastName?: string | null;
  photoUrl?: string | null;
  refererCode?: string | null;
  username?: string | null;
}

export interface TgLoginRequest {
  authDate: string;
  firstName?: string | null;
  hash: string;
  id: string;
  lastName?: string | null;
  photoUrl?: string | null;
  refererCode?: string | null;
  username?: string | null;
}

export interface TgMiniappLoginRequest {
  checkString: string;
  refererCode?: string | null;
}

export interface TwitterBindRequest {
  oauthToken: string;
  oauthVerifier: string;
}

export interface UserModifiers {
  /** @format BigDecimal */
  bonusXp: string | null;
  /** @format BigDecimal */
  modifier: string | null;
  /** @format int64 */
  rankFrom: number;
  /** @format int64 */
  rankTo: number;
  /** @format int32 */
  season: number;
  stakeType: string;
}

export interface UserReferralStatsResponseDto {
  refCode: string;
  stats: ReferralLevelInfo[];
}

export interface WalletBindRequest {
  address: string;
  publicKey: string;
  signature: string;
  /**
   * @format int64
   * @min 0
   */
  timestamp: number;
  walletType: string;
}

export interface WalletLoginRequest {
  address: string;
  publicKey: string;
  refererCode?: string | null;
  signature: string;
  /**
   * @format int64
   * @min 0
   */
  timestamp: number;
  walletType: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://vn-indexer-stage.bf.works',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title api
 * @version 0.1.0
 * @license
 * @baseUrl https://vn-indexer-stage.bf.works
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  info = {
    /**
     * No description
     *
     * @tags Info
     * @name GetInfo
     * @request GET:/info
     */
    getInfo: (
      query: {
        season: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetInfoResponseDto, any>({
        path: `/info`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostInfo
     * @request POST:/info
     * @secure
     */
    postInfo: (data: PostInfoPayload, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/info`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostLeaderboard
     * @request POST:/leaderboard
     */
    postLeaderboard: (data: PostLeaderboardRequestDto, params: RequestParams = {}) =>
      this.request<PostLeaderboardResponseDto, any>({
        path: `/leaderboard`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialLeaderboard
     * @request POST:/leaderboard/social
     */
    postSocialLeaderboard: (data: PostSocialLeaderboardRequestDto, params: RequestParams = {}) =>
      this.request<PostSocialLeaderboardResponseDto, any>({
        path: `/leaderboard/social`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostReferralsList
     * @request POST:/referral/list
     * @secure
     */
    postReferralsList: (data: PostReferralsListRequestDto, params: RequestParams = {}) =>
      this.request<PostReferralsListResponseDto, any>({
        path: `/referral/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name GetUserReferralStats
     * @request GET:/referral/stats
     * @secure
     */
    getUserReferralStats: (
      query: {
        season: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserReferralStatsResponseDto, any>({
        path: `/referral/stats`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialCheck
     * @request POST:/social/check
     * @secure
     */
    postSocialCheck: (data: PostSocialCheckRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/social/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialTelegramCheck
     * @request POST:/social/telegram/auth
     * @secure
     */
    postSocialTelegramCheck: (data: PostSocialTelegramAuthRequestDto, params: RequestParams = {}) =>
      this.request<PostSocialTelegramAuthResponseDto, any>({
        path: `/social/telegram/auth`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialTelegramRefresh
     * @request POST:/social/telegram/refresh
     * @secure
     */
    postSocialTelegramRefresh: (data: PostSocialTelegramAuthRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/social/telegram/refresh`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialTwitterBind
     * @request POST:/social/twitter/bind
     * @secure
     */
    postSocialTwitterBind: (data: PostSocialTwitterBindRequestDto, params: RequestParams = {}) =>
      this.request<PostSocialTwitterBindResponseDto, any>({
        path: `/social/twitter/bind`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialTwitterLink
     * @request GET:/social/twitter/link
     */
    postSocialTwitterLink: (params: RequestParams = {}) =>
      this.request<GetSocialTwitterLinkResponseDto, any>({
        path: `/social/twitter/link`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostSocialTwitterText
     * @request POST:/social/twitter/text
     */
    postSocialTwitterText: (data: PostSocialTwitterTextRequestDto, params: RequestParams = {}) =>
      this.request<PostSocialTwitterTextResponseDto, any>({
        path: `/social/twitter/text`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostStatsGeneral
     * @request POST:/stats/general
     */
    postStatsGeneral: (data: PostStatsGeneralRequestDto, params: RequestParams = {}) =>
      this.request<PostStatsGeneralResponseDto, any>({
        path: `/stats/general`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserBind
     * @request POST:/user/bind
     * @secure
     */
    postUserBind: (data: PostUserBindRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/bind`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserHistory
     * @request POST:/user/history
     * @secure
     */
    postUserHistory: (data: PostUserHistoryRequestDto, params: RequestParams = {}) =>
      this.request<PostUserHistoryResponseDto, any>({
        path: `/user/history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserInfo
     * @request POST:/user/info
     * @secure
     */
    postUserInfo: (params: RequestParams = {}) =>
      this.request<PostUserInfoResponseDto, any>({
        path: `/user/info`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name GetUserRewardClaim
     * @request GET:/user/reward/claim
     * @secure
     */
    getUserRewardClaim: (params: RequestParams = {}) =>
      this.request<GetUserRewardClaimResponseDto, any>({
        path: `/user/reward/claim`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserRewardClaim
     * @request POST:/user/reward/claim
     * @secure
     */
    postUserRewardClaim: (params: RequestParams = {}) =>
      this.request<PostUserRewardClaimResponseDto, any>({
        path: `/user/reward/claim`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PatchUserRewardClaim
     * @request PATCH:/user/reward/claim
     */
    patchUserRewardClaim: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/reward/claim`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name GetUserModifiers
     * @request GET:/user/reward/modifiers
     * @secure
     */
    getUserModifiers: (params: RequestParams = {}) =>
      this.request<GetUserModifiersResponseDto, any>({
        path: `/user/reward/modifiers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name GetUserRewardStack
     * @request GET:/user/reward/stake
     * @secure
     */
    getUserRewardStack: (params: RequestParams = {}) =>
      this.request<GetUserRewardStakeResponseDto, any>({
        path: `/user/reward/stake`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserRewardText
     * @request POST:/user/reward/text
     */
    postUserRewardText: (data: PostUserRewardTextRequestDto, params: RequestParams = {}) =>
      this.request<PostUserRewardTextResponseDto, any>({
        path: `/user/reward/text`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Info
     * @name PostUserStats
     * @request POST:/user/stats
     * @secure
     */
    postUserStats: (data: PostUserStatsRequestDto, params: RequestParams = {}) =>
      this.request<PostUserStatsResponseDto, any>({
        path: `/user/stats`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @request POST:/login
     */
    login: (data: AuthDataDTO, params: RequestParams = {}) =>
      this.request<LoginResponseDTO, any>({
        path: `/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Refresh
     * @request POST:/refresh
     */
    refresh: (data: RefreshRequestDto, params: RequestParams = {}) =>
      this.request<RefreshResponseDto, any>({
        path: `/refresh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
