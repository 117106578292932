import styles from './main-page-header.module.css';
import headerBg from 'images/main/header-bg.png';

import { CONFIG } from 'config';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'routes';

export const MainPageHeader = memo(() => {
  const intl = useIntl();

  return (
    <div className={styles.main}>
      <h1>
        {intl.formatMessage({
          id: 'page.main.header',
          defaultMessage: 'Venom Quests',
        })}
      </h1>

      <h1>
        {intl.formatMessage(
          {
            id: 'page.main.header.season',
            defaultMessage: 'Season {number}',
          },
          { number: CONFIG.season.current }
        )}
      </h1>

      <Link to={AppRoutes.prizes.fullPath}>
        <button className={styles.how_btn}>
          {intl.formatMessage({
            id: 'page.main.how_it_works',
            defaultMessage: 'How it works?',
          })}
        </button>
      </Link>

      <img src={headerBg} loading='lazy' alt='' />
    </div>
  );
});
