import styles from './navbar.module.css';
import BigNumber from 'bignumber.js';

import { cutAddress } from 'utils/format';
import { XpLabel } from 'components/common';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useBaseStores } from 'providers/base-stores-provider';

import { ReactComponent as VenomSvg } from 'images/icons/venom.svg';

export const LayoutNavbar = observer(() => {
  const intl = useIntl();

  const { userStore } = useBaseStores();

  return (
    <div className={styles.main}>
      <div className={styles.brand}>
        <VenomSvg />

        {!!userStore.currentUser?.wallet && (
          <span>{cutAddress(userStore.currentUser.wallet)}</span>
        )}
      </div>

      <div className={styles.total_label}>
        <span>
          {intl.formatMessage({ id: 'navbar.total', defaultMessage: 'Total' })}
        </span>

        <XpLabel
          size='lg'
          value={userStore.stats?.globalTotalXp ?? BigNumber(0)}
        />
      </div>
    </div>
  );
});
