import styles from './prizes-page-how-it-works-faq.module.css';
import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { getFaqData } from './prizes-page-how-it-works-data';
import { FaMinus, FaPlus } from 'react-icons/fa6';

export const PrizesPageFaq = memo(() => {
  const intl = useIntl();

  const faqData = useMemo(() => getFaqData(intl), [intl]);

  return (
    <div className={styles.main}>
      <h3 className={styles.title}>
        {intl.formatMessage({
          id: 'page.prizes.faq.title',
          defaultMessage: 'FAQ',
        })}
      </h3>

      <div className={styles.items}>
        {faqData.map((x) => (
          <FaqItem key={x.question} question={x.question} answer={x.answer} />
        ))}
      </div>
    </div>
  );
});

type Props = {
  question: string;
  answer: string;
};

const FaqItem = memo(({ question, answer }: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <button className={styles.item} onClick={() => setCollapsed((val) => !val)}>
      <div className={styles.question}>
        {collapsed ? <FaPlus /> : <FaMinus />}
        <span>{question}</span>
      </div>

      {!collapsed && <div className={styles.answer}>{answer}</div>}
    </button>
  );
});
