import styles from './loader.module.css';

import { memo } from 'react';

import loaderPng from 'images/maintenance/loader.png';

export const LayoutLoader = memo(() => {
  return (
    <div className={styles.main}>
      <img src={loaderPng} width={96} height={96} alt='' loading='lazy' />
    </div>
  );
});
