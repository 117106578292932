import styles from './prize-pool-main.module.css';

import { useIntl } from 'react-intl';
import { memo, useMemo } from 'react';
import { CONFIG } from 'config';
import { getPrizePoolDataForSeason } from '../prize-pool-data';

import prizePoolBgPng from 'images/prizes/prize-pool-bg.png';

export const PrizePoolMain = memo(() => {
  const intl = useIntl();

  const prizePoolData = useMemo(
    () => getPrizePoolDataForSeason(CONFIG.season.current, intl)[0],
    [intl]
  );

  return (
    <div className={styles.main_outer}>
      <div
        className={styles.main_inner}
        style={{
          backgroundImage: `url(${prizePoolBgPng}), url(${prizePoolBgPng})`,
        }}
      >
        <div className={styles.title}>
          {intl.formatMessage({
            id: 'prize_pool.title',
            defaultMessage: 'Prize pool',
          })}
        </div>

        <div className={styles.prize}>
          <div className={styles.prize_value}>{prizePoolData.value}</div>
          <div className={styles.prize_description}>
            {prizePoolData.description}
          </div>
        </div>
      </div>
    </div>
  );
});
