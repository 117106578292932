import { IntlShape } from 'react-intl';

export const getPrizePoolDataForSeason = (season: number, intl: IntlShape) => {
  switch (season) {
    default:
      return [
        {
          value: '10,000,000',
          description: intl.formatMessage({
            id: 'prize_pool.prize.main',
            defaultMessage: '$VENOM Main Leaderboard',
          }),
        },
        {
          value: '1,000,000',
          description: intl.formatMessage({
            id: 'prize_pool.prize.social',
            defaultMessage: '$VENOM Social Tasks Leaderboard',
          }),
        },
        {
          value: 'Additional Rewards',
          description: intl.formatMessage({
            id: 'prize_pool.prize.additional',
            defaultMessage:
              'Testnet User Rewards + NFTs, Tokens, Digital Goods from Venom Partners, Projects, etc.',
          }),
        },
      ];
  }
};
