import styles from './stage-status-badge.module.css';
import classNames from 'classnames';

import { useIntl } from 'react-intl';
import { memo, useMemo } from 'react';
import { StageStatus } from 'models/stage-model';

export const StageStatusBadge = memo(({ status }: { status: StageStatus }) => {
  const intl = useIntl();

  const badgeData = useMemo(() => {
    switch (status) {
      case 'live':
        return {
          text: intl.formatMessage({
            id: 'badge.stage_status.live',
            defaultMessage: 'Live',
          }),
          styleClass: styles.live,
        };

      case 'completed':
        return {
          text: intl.formatMessage({
            id: 'badge.stage_status.completed',
            defaultMessage: 'Completed',
          }),
          styleClass: styles.completed,
        };

      default:
        return null;
    }
  }, [intl, status]);

  if (!badgeData) {
    return null;
  }

  return (
    <div className={classNames(styles.main, badgeData.styleClass)}>
      {badgeData.text}
    </div>
  );
});
