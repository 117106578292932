import styles from './friends-page.module.css';

import { memo } from 'react';
import { ProvideFriendsPageStore } from './friends-page-store-provider';
import { FriendsPageTabs } from './tabs/friends-page-tabs';
import { FriendsPageStats } from './stats/friends-page-stats';
import { FriendsPageList } from './list/friends-page-list';

const FriendsPage = memo(() => {
  return (
    <ProvideFriendsPageStore>
      <div className={styles.main}>
        <FriendsPageStats />
        <FriendsPageTabs />
        <FriendsPageList />
      </div>
    </ProvideFriendsPageStore>
  );
});

export default FriendsPage;
