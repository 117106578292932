import styles from './check-task-button.module.css';
import classNames from 'classnames';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { TaskStore } from '../task-store';
import { useCallback } from 'react';

export const CheckTaskButton = observer(({ store }: { store: TaskStore }) => {
  const intl = useIntl();

  const handleCheckTask = useCallback(() => {
    store.checkTask();
  }, [store]);

  if (!store.isTaskInteractable) {
    return null;
  }

  if (!store.canCheckTask) {
    return (
      <button
        className={classNames(styles.task_btn, styles.follow_btn)}
        disabled={store.isCooldownInProgress}
      >
        {store.isCooldownInProgress ? (
          <span>{store.cooldownSeconds}</span>
        ) : (
          <span>
            {intl.formatMessage({
              id: 'task.check.follow_instructions',
              defaultMessage:
                'Follow the instructions and you can check the task',
            })}
          </span>
        )}
      </button>
    );
  }

  return (
    <button
      className={classNames(styles.task_btn, styles.check_btn)}
      onClick={handleCheckTask}
      disabled={store.isCheckTaskInProgress}
    >
      <span>
        {intl.formatMessage({
          id: 'task.check',
          defaultMessage: 'Check task',
        })}
      </span>
    </button>
  );
});
