import styles from './leaderboard-page-list.module.css';
import classNames from 'classnames';

import { observer } from 'mobx-react-lite';
import { cutAddress, formatExp } from 'utils/format';
import { useLeaderboardPageStore } from '../leaderboard-page-store-provider';
import { useIntl } from 'react-intl';
import { LeaderboardType } from '../leaderboard-page-store';
import { LeaderboardPlayerModel } from 'models';
import { memo } from 'react';

export const LeaderboardPageList = observer(() => {
  const intl = useIntl();
  const pageStore = useLeaderboardPageStore();

  if (!pageStore.leaderboardCollection.items) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.list_wrap}>
        <table className={styles.list}>
          <thead>
            <tr className={styles.header}>
              <th>
                {intl.formatMessage({
                  id: 'page.leaderboard.list.header.rank',
                  defaultMessage: 'Rank',
                })}
              </th>
              <th>
                {pageStore.leaderboardType === LeaderboardType.Main && (
                  <>
                    {intl.formatMessage({
                      id: 'page.leaderboard.list.header.wallet',
                      defaultMessage: 'Wallet',
                    })}
                  </>
                )}

                {pageStore.leaderboardType === LeaderboardType.Social && (
                  <>
                    {intl.formatMessage({
                      id: 'page.leaderboard.list.header.user',
                      defaultMessage: 'User',
                    })}
                  </>
                )}
              </th>
              <th>
                {intl.formatMessage({
                  id: 'page.leaderboard.list.header.xp',
                  defaultMessage: 'XP Gained',
                })}
              </th>
            </tr>
          </thead>

          <tbody>
            {pageStore.leaderboardCollection.items?.map((x) => (
              <LeaderboardTableItem
                key={x.id}
                model={x}
                leaderboardType={pageStore.leaderboardType}
                isCurrentUser={pageStore.currentUser?.id === x.id}
              />
            ))}

            {pageStore.showCurrentUserSeparately && !!pageStore.currentUser && (
              <LeaderboardTableItem
                model={pageStore.currentUser}
                leaderboardType={pageStore.leaderboardType}
                isCurrentUser={true}
              />
            )}
          </tbody>
        </table>
      </div>

      {pageStore.leaderboardCollection.canLoadMore && (
        <button
          className={styles.load_more}
          onClick={() => pageStore.leaderboardCollection.fetchMore()}
        >
          {intl.formatMessage({
            id: 'common.button.load_more',
            defaultMessage: 'Load more...',
          })}
        </button>
      )}
    </div>
  );
});

type LeaderboardTableItemProps = {
  model: LeaderboardPlayerModel;
  leaderboardType: LeaderboardType;
  isCurrentUser: boolean;
};

const LeaderboardTableItem = memo(
  ({ model, leaderboardType, isCurrentUser }: LeaderboardTableItemProps) => {
    return (
      <tr
        className={classNames(styles.item, {
          [styles.item_current_user]: isCurrentUser,
        })}
      >
        <td className={styles.rank}>
          <div>{model.rank > 0 ? model.rank : '-'}</div>
        </td>
        {leaderboardType === LeaderboardType.Main ? (
          <td>
            {model.wallet ? cutAddress(model.wallet) : cutAddress(model.id)}
          </td>
        ) : (
          <td>{model.account ?? cutAddress(model.id)}</td>
        )}
        <td>{formatExp(model.exp)}</td>
      </tr>
    );
  }
);
