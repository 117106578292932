import { Buffer as BufferBrowser } from 'buffer';
import { ProviderRpcClient } from 'everscale-inpage-provider';

type Props = {
  message: string;
  publicKey: string;
  provider: ProviderRpcClient;
};

export const signMessage = async ({ message, publicKey, provider }: Props) => {
  const base64Msg = BufferBrowser.from(message, 'utf-8').toString('base64');

  const result = await provider.signData({
    data: base64Msg,
    publicKey: publicKey,
  });

  return { signature: result.signature };
};
