import styles from './main-page-stages.module.css';
import classNames from 'classnames';

import claimPng from 'images/icons/claim.png';
import liveSvg from 'images/icons/live.svg';
import checkSvg from 'images/icons/check.svg';

import { observer } from 'mobx-react-lite';
import { useMainPageStore } from '../main-page-store-provider';
import { useIntl } from 'react-intl';

export const MainPageStages = observer(() => {
  const intl = useIntl();
  const mainPageStore = useMainPageStore();

  return (
    <div className={styles.main}>
      {mainPageStore.stages.map((x) => (
        <button
          key={x.stageIndex}
          className={classNames(
            styles.stage,
            {
              [styles.stage_completed]: x.status === 'completed',
            },
            {
              [styles.stage_active]:
                x.id === mainPageStore.currentSelectedStage?.id,
            }
          )}
          onClick={() => mainPageStore.setCurrentStage(x)}
          disabled={x.status === 'upcoming'}
        >
          {x.status === 'live' && (
            <img src={liveSvg} width={20} height={20} alt='' />
          )}

          {x.status === 'completed' && (
            <img src={checkSvg} width={20} height={20} alt='' />
          )}

          <span>
            {intl.formatMessage(
              { id: 'page.main.stages.stage', defaultMessage: 'Stage {num}' },
              { num: x.stageIndex }
            )}
          </span>
        </button>
      ))}

      <button className={classNames(styles.stage, styles.stage_claim)} disabled>
        <img src={claimPng} width={20} height={20} alt='' />

        <span>
          {intl.formatMessage({
            id: 'page.main.stages.stage.claim',
            defaultMessage: 'Claim Rewards',
          })}
        </span>
      </button>
    </div>
  );
});
