import { makeAutoObservable, runInAction } from 'mobx';
import { TelegramStore } from './telegram-store';
import { AuthStore } from './auth-store';
import { UserStore } from './user-store';

export class AppStore {
  constructor(
    private telegramStore: TelegramStore,
    private authStore: AuthStore,
    private userStore: UserStore
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _initStatus: { initialized: boolean; error?: string } = {
    initialized: false,
  };

  get initStatus() {
    return this._initStatus;
  }

  async initApp() {
    if (this.initStatus.initialized === true) {
      console.warn("Cannot init app as it's already initialized.");
      return;
    }

    try {
      this.telegramStore.init();

      await this.authStore.init();
      await this.userStore.init();

      this.telegramStore.ready();

      runInAction(() => {
        this._initStatus = {
          initialized: true,
        };
      });
    } catch (err: any) {
      runInAction(() => {
        this._initStatus = {
          initialized: true,
          error: err.toString(),
        };
      });
    }
  }
}
