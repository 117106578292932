export const getCasesPrizeData = () =>
  [
    {
      text: '1-10',
      cases: ['legendary', 'legendary', 'magic'],
    },
    {
      text: '11-100',
      cases: ['legendary', 'rare'],
    },
    {
      text: '101-1000',
      cases: ['legendary', 'common'],
    },
    {
      text: '1001-5000',
      cases: ['epic', 'magic'],
    },
    {
      text: '5001-10,000',
      cases: ['epic', 'uncommon'],
    },
    {
      text: '10,001-15,000',
      cases: ['epic'],
    },
    {
      text: '15,001-20,000',
      cases: ['rare', 'rare'],
    },
    {
      text: '20,001-30,000',
      cases: ['rare', 'common'],
    },
    {
      text: '30,001-50,000',
      cases: ['rare'],
    },
    {
      text: '50k-100k',
      cases: ['uncommon', 'uncommon'],
    },
    {
      text: '100k-200k',
      cases: ['uncommon', 'common'],
    },
    {
      text: '200k-400k',
      cases: ['uncommon'],
    },
    {
      text: '400k-700k',
      cases: ['common', 'common'],
    },
    {
      text: '700k-1M',
      cases: ['common'],
    },
  ] as const;
