import styles from './button.module.css';
import React from 'react';
import classNames from 'classnames';

import { Spinner } from '../spinner';

export type ButtonVariants = 'primary';

type ButtonProps = {
  variant?: ButtonVariants;
  showSpinner?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      showSpinner = false,
      className,
      children,
      ...buttonProps
    },
    ref
  ) => (
    <button
      {...buttonProps}
      className={classNames(
        styles.main,
        { [styles.primary]: variant === 'primary' },
        className
      )}
      ref={ref}
    >
      {children}

      {showSpinner && <Spinner />}
    </button>
  )
);
