import styles from './prize-pool-all.module.css';

import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getPrizePoolDataForSeason } from '../prize-pool-data';
import { CONFIG } from 'config';

export const PrizePoolAll = memo(() => {
  const intl = useIntl();

  const prizePoolData = useMemo(
    () => getPrizePoolDataForSeason(CONFIG.season.current, intl),
    [intl]
  );

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        {intl.formatMessage({
          id: 'prize_pool.title',
          defaultMessage: 'Prize pool',
        })}
      </div>

      <div className={styles.prize_list}>
        {prizePoolData.map((x, i) => (
          <div key={x.value} className={styles.prize}>
            <div className={styles.prize_outer}>
              <div className={styles.prize_inner}>
                <span className={styles.prize_value}>{x.value}</span>
                <span className={styles.prize_description}>
                  {x.description}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
