import styles from './info-html.module.css';

import { memo, useCallback } from 'react';
import { TaskModel } from 'models';

export const TaskInfoHtml = memo(
  ({
    task,
    onInternalLinkClick,
  }: {
    task: TaskModel;
    onInternalLinkClick?: (href: string) => void;
  }) => {
    const handleInternalLinkClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        if (!(e.target instanceof Element) || e.target.tagName !== 'A') {
          return;
        }

        e.preventDefault();

        const href = e.target.getAttribute('href');

        if (!href) {
          return;
        }

        onInternalLinkClick?.(href);
      },
      [onInternalLinkClick]
    );

    if (!task.additionalInfoHtml) {
      return null;
    }

    return (
      <div
        className={styles.task_info_html}
        dangerouslySetInnerHTML={{ __html: task.additionalInfoHtml }}
        onClick={handleInternalLinkClick}
      />
    );
  }
);
