import styles from './blue-task-icon.module.css';
import classNames from 'classnames';

import { memo } from 'react';
import { ReactComponent as TaskIconSvg } from 'images/icons/task-blue.svg';

export const BlueTaskIcon = memo(({ size = 'md' }: { size?: 'sm' | 'md' }) => {
  return (
    <TaskIconSvg
      className={classNames(
        styles.main,
        { [styles.sm]: size === 'sm' },
        { [styles.md]: size === 'md' }
      )}
    />
  );
});
