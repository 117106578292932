import { CaseImage } from 'components/common/case-image/case-image';
import { IntlShape } from 'react-intl';

export const getCasesShowcaseData = (intl: IntlShape) => [
  {
    text: intl.formatMessage({
      id: 'case.rarity.common',
      defaultMessage: 'Common',
    }),
    image: <CaseImage type='common' />,
  },
  {
    text: intl.formatMessage({
      id: 'case.rarity.uncommon',
      defaultMessage: 'Uncommon',
    }),
    image: <CaseImage type='uncommon' />,
  },
  {
    text: intl.formatMessage({
      id: 'case.rarity.rare',
      defaultMessage: 'Rare',
    }),
    image: <CaseImage type='rare' />,
  },
  {
    text: intl.formatMessage({
      id: 'case.rarity.epic',
      defaultMessage: 'Epic',
    }),
    image: <CaseImage type='epic' />,
  },
  {
    text: intl.formatMessage({
      id: 'case.rarity.legendary',
      defaultMessage: 'Legendary',
    }),
    image: <CaseImage type='legendary' />,
  },
];

export const getSpecialCasesShowcaseData = (intl: IntlShape) => [
  {
    text: intl.formatMessage({
      id: 'case.rarity.magical',
      defaultMessage: 'Magical',
    }),
    image: <CaseImage type='magic' />,
  },
  {
    text: intl.formatMessage({
      id: 'case.rarity.Fairytale',
      defaultMessage: 'Fairtytale',
    }),
    image: <CaseImage type='testnet' />,
  },
];
