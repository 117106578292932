import dayjs from 'dayjs';

import { StageInfo } from 'api';

export type StageStatus = 'completed' | 'live' | 'upcoming';

export class StageModel {
  constructor(dto: StageInfo) {
    this.id = dto.id;
    this.stageIndex = dto.stage;
    this.descriptionHtml = dto.description;
    this.startDate = dto.startDate;
    this.endDate = dto.endDate;

    const now = dayjs().unix();

    if (now >= dto.startDate && now <= dto.endDate) {
      this.status = 'live';
      return;
    }

    this.status = now > dto.endDate ? 'completed' : 'upcoming';
  }

  id: number;
  stageIndex: number;

  startDate: number;
  endDate: number;

  status: StageStatus;

  descriptionHtml?: string;
}
