import dayjs from 'dayjs';
import VenomConnect from 'venom-connect';

import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { Address, ProviderRpcClient } from 'everscale-inpage-provider';
import { AccountType, getAuthSecurityWorker, VenomNetworkApi } from 'api';
import { signMessage } from 'utils/sign-message';

type Account = {
  address: Address;
  publicKey: string;
  walletType: string;
  networkId: number;
};

function getLoginMsg(address: string, timestamp: number) {
  return `I want to login to Venom Network with address ${address} at ${timestamp}`;
}

export class ConnectWalletPageStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _api = new VenomNetworkApi({
    baseURL: CONFIG.api.baseUrl,
    securityWorker: getAuthSecurityWorker,
  });

  private _venomConnect = new VenomConnect({
    theme: 'dark',
    checkNetworkId: 1,
    providersOptions: {
      venomwallet: {
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback:
                VenomConnect.getPromise('venomwallet', 'extension') ||
                (() => Promise.reject()),
              forceUseFallback: true,
            },
            id: 'extension',
            type: 'extension',
          },
        ],
        defaultWalletWaysToConnect: ['mobile', 'ios', 'android'],
      },
    },
  });

  private _initState: { initialized: boolean; error?: string } = {
    initialized: false,
  };

  private _bindState: { inProgress: boolean; wallet?: string; error?: string } =
    {
      inProgress: false,
    };

  private _providerState: {
    provider: ProviderRpcClient;
    account: Account;
  } | null = null;

  get initState() {
    return this._initState;
  }

  get bindState() {
    return this._bindState;
  }

  async init() {
    try {
      this.loginViaToken();

      await this.fetchAccounts();

      this._venomConnect.on('extension-auth', this.onProviderChanged);

      runInAction(() => {
        this._initState = {
          initialized: true,
        };
      });

      if (!this._bindState.wallet) {
        this.connect();
      }
    } catch (err: any) {
      runInAction(() => {
        this._initState = {
          initialized: true,
          error: err.toString(),
        };
      });
    }
  }

  async connect() {
    return this._venomConnect.connect();
  }

  async disconnect() {
    await this._providerState?.provider.disconnect();
    await this.onProviderChanged();
  }

  navigateBackToApp() {
    window.location.replace(CONFIG.telegramBotApp.deepLink);
  }

  private async bindWalletToTelegram() {
    if (!this._providerState || !!this.bindState.wallet) {
      return;
    }

    try {
      runInAction(() => {
        this._bindState = {
          inProgress: true,
        };
      });

      const account = this._providerState.account;
      const provider = this._providerState.provider;

      const timestamp = dayjs().unix();

      const signedMsg = await signMessage({
        message: getLoginMsg(account.address.toString(), timestamp),
        publicKey: account.publicKey,
        provider: provider,
      });

      await this._api.info.postUserBind({
        address: account.address.toString(),
        publicKey: account.publicKey,
        walletType: account.walletType,
        timestamp: timestamp,
        signature: signedMsg.signature,
        type: 'wallet',
      });

      runInAction(() => {
        this._bindState = {
          inProgress: false,
          wallet: account.address.toString(),
        };
      });
    } catch (err: any) {
      this.disconnect();

      const data = err?.response?.data;

      runInAction(() => {
        this._bindState = {
          inProgress: false,
          error: data,
        };
      });
    }
  }

  private loginViaToken() {
    const searchParams = new URLSearchParams(window.location.search);
    const accessToken = searchParams.get('token');

    if (!accessToken) {
      throw new Error('Token was not provided.');
    }

    this._api.setSecurityData(accessToken);

    window.history.replaceState({}, document.title, window.location.pathname);
  }

  private async fetchAccounts() {
    const result = await this._api.info.postUserInfo();

    const wallet = result.data.accounts.find(
      (x) => x.accountType === AccountType.Wallet
    );

    if (!wallet) {
      return;
    }

    runInAction(() => {
      this._bindState = {
        inProgress: false,
        wallet: wallet.accountId,
      };
    });
  }

  private async onProviderChanged(provider?: ProviderRpcClient | null) {
    const state = await provider?.getProviderState();
    const accountInteraction = state?.permissions.accountInteraction;
    const networkId = state?.networkId;

    const account =
      accountInteraction && networkId
        ? {
            address: accountInteraction.address,
            publicKey: accountInteraction.publicKey,
            walletType: accountInteraction.contractType,
            networkId: networkId,
          }
        : null;

    if (!provider || !account) {
      runInAction(() => {
        this._providerState = null;
      });
      return;
    }

    runInAction(() => {
      this._providerState = {
        provider: provider,
        account: account,
      };
    });

    await this.bindWalletToTelegram();
  }
}
