import { IntlShape } from 'react-intl';

export const getFaqData = (intl: IntlShape) => [
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q1',
      defaultMessage: 'What is Venom Quests Season 3?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q1.answer',
      defaultMessage:
        'It is a community incentive program that will help users to discover products inside the Venom ecosystem.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q2',
      defaultMessage: 'Will there be any rewards?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q2.answer',
      defaultMessage:
        'Third season, we have allocated 10,000,000 $VENOM Main Prize Pool + 1,000,000 $VENOM Prize Pool for Social Tasks.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q3',
      defaultMessage: 'Where can I complete Social Tasks?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q3.answer',
      defaultMessage:
        'You can complete social tasks on the Venom Network website and in our Telegram app.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q4',
      defaultMessage: 'How can I claim rewards?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q4.answer',
      defaultMessage:
        'Complete quests during stages, and based on your final XP when the season ends, you will be eligible to claim your rewards. You will receive a reward in Venom Cases. For detailed information on Venom Case distribution, please refer to the table in the "How it works?" section.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q5',
      defaultMessage: 'When does Venom Quests Season 3 end?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q5.answer',
      defaultMessage: 'The third season ends in October.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q6',
      defaultMessage: 'How many stages will there be in the third season?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q6.answer',
      defaultMessage: 'There will be a total of 6 stages.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q7',
      defaultMessage: 'How long will each stage last?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q7.answer',
      defaultMessage:
        'The estimated duration of each stage is from 7 to 14 days.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q8',
      defaultMessage: 'Are you planning to have more seasons and rewards?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q8.answer',
      defaultMessage: 'Stay tuned.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q9',
      defaultMessage: 'What is XP, and how is it counted?',
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q9.answer',
      defaultMessage:
        'By completing tasks, you earn XP. XP will determine whether you are eligible for rewards.',
    }),
  },
  {
    question: intl.formatMessage({
      id: 'page.prizes.faq.q10',
      defaultMessage:
        "Why don't I see bonus XP for completing all stage tasks in my XP history?",
    }),
    answer: intl.formatMessage({
      id: 'page.prizes.faq.q10.answer',
      defaultMessage:
        'The XP for completing all stage tasks will be credked to your account, but it may not be immediately visible in your XP history. Rest assured, the XP is being accounted for. Additionally, please note that the total VenomStake multiplier also applies to bonus points.',
    }),
  },
];
