import { ConnectWalletPageStore } from './connect-wallet-page-store';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

const storeContext = createContext<ConnectWalletPageStore>(
  {} as ConnectWalletPageStore
);

type Props = {
  children?: React.ReactNode;
};
export const ProvideConnectWalletPageStore = observer(({ children }: Props) => {
  const store = useLocalObservable(() => new ConnectWalletPageStore());

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.initState.initialized) {
    return null;
  }

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useConnectWalletPageStore = () => {
  return useContext(storeContext);
};
