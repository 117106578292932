import BigNumber from 'bignumber.js';

import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { VenomNetworkApi } from 'api';
import { AuthStore } from './auth-store';

type UserStats = {
  mainLeaderboard: {
    totalXp: BigNumber;
    rank: number;
  };

  socialLeaderboard: {
    totalXp: BigNumber;
    rank: number;
  };

  globalTotalXp: BigNumber;
};

export class UserStore {
  constructor(
    private authStore: AuthStore,
    private venomNetworkApi: VenomNetworkApi
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _stats: UserStats | null = null;
  private _completedTasksIds = new Set<number>();

  get stats() {
    return this._stats;
  }

  get currentUser() {
    return this.authStore.userData;
  }

  async init() {
    await this.fetchCurrentUserStats();
  }

  async refresh() {
    return this.init();
  }

  isTaskCompleted(taskId: number) {
    return this._completedTasksIds.has(taskId);
  }

  private async fetchCurrentUserStats() {
    const result = await this.venomNetworkApi.info.postUserStats({
      season: CONFIG.season.current,
    });

    const mainLeaderboard = {
      totalXp: BigNumber(result.data.total?.exp ?? 0),
      rank: result.data.total?.rank ?? 0,
    };

    const socialLeaderboard = {
      totalXp: BigNumber(result.data.total?.socialExp ?? 0),
      rank: result.data.total?.socialRank ?? 0,
    };

    const stats = {
      mainLeaderboard: mainLeaderboard,
      socialLeaderboard: socialLeaderboard,
      globalTotalXp: BigNumber.max(
        mainLeaderboard.totalXp,
        socialLeaderboard.totalXp
      ),
    };

    runInAction(() => {
      this._stats = stats;
      this._completedTasksIds = new Set(result.data.tasks);
    });
  }
}
