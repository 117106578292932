import FriendsPage from 'modules/friends/friends-page';
import GoPage from 'modules/go/go-page';
import LeaderboardPage from 'modules/leaderboard/leaderboard-page';
import MainPage from 'modules/main/main-page';
import PrizesPage from 'modules/prizes/prizes-page';

import { CONFIG } from 'config';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/base-stores-provider';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppRoutes } from 'routes';
import {
  LayoutLoader,
  LayoutInitError,
  LayoutBody,
  LayoutScrollToTop,
  LayoutNavbar,
  LayoutPage,
  LayoutMenu,
} from './layout';

export const AppMain = observer(() => {
  const { appStore } = useBaseStores();

  if (!appStore.initStatus.initialized) {
    return <LayoutLoader />;
  }

  if (!!appStore.initStatus.error && CONFIG.env !== 'DEV') {
    return <LayoutInitError error={appStore.initStatus.error} />;
  }

  return (
    <LayoutBody>
      <LayoutScrollToTop />

      <LayoutNavbar />

      <LayoutPage>
        <Routes>
          <Route path={AppRoutes.index.path} element={<MainPage />} />
          <Route
            path={AppRoutes.leaderboard.path}
            element={<LeaderboardPage />}
          />
          <Route path={AppRoutes.go.path} element={<GoPage />} />
          <Route path={AppRoutes.friends.path} element={<FriendsPage />} />
          <Route path={AppRoutes.prizes.path} element={<PrizesPage />} />
          <Route
            path='*'
            element={<Navigate to={AppRoutes.index.fullPath} replace />}
          />
        </Routes>
      </LayoutPage>

      <LayoutMenu />
    </LayoutBody>
  );
});
