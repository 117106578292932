import styles from './go-page.module.css';

import { CONFIG } from 'config';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Button, PrizePool } from 'components/common';
import { useBaseStores } from 'providers/base-stores-provider';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ExternalLink } from 'components/common';
import { CopyInitDataButton } from 'components/layout';
import { GoPageStore } from './go-page-store';

import connectVenomWalletSvg from 'images/prizes/connect-venom-wallet.svg';
import venomWalletConnectedSvg from 'images/prizes/venom-wallet-connected.svg';

const GoPage = observer(() => {
  const intl = useIntl();
  const { userStore, telegramStore, authStore } = useBaseStores();

  const store = useLocalObservable(
    () => new GoPageStore(userStore, telegramStore, authStore)
  );

  const venomNetworkLink = useMemo(
    () => (
      <ExternalLink
        url={`${CONFIG.venomWallet.linkGenerator}${CONFIG.venomNetwork.url}`}
        text='Venom Network'
      />
    ),
    []
  );

  const onConnectClick = useCallback(() => {
    store.connectWallet();
  }, [store]);

  return (
    <div className={styles.main}>
      <PrizePool variant='main' />

      {store.showConnectWallet ? (
        <div className={styles.instructions}>
          <span>
            {intl.formatMessage({
              id: 'pages.go.connect_wallet',
              defaultMessage:
                'To view this page you need to connect your Venom Wallet.',
            })}
          </span>

          <Button
            className={styles.space}
            onClick={onConnectClick}
            variant='primary'
          >
            {intl.formatMessage({
              id: 'pages.go.connect_wallet.button',
              defaultMessage: 'Connect Wallet',
            })}
          </Button>

          <img
            className={styles.connect_wallet_img}
            src={connectVenomWalletSvg}
            width={486}
            height={480}
            loading='lazy'
            alt=''
          />
        </div>
      ) : (
        <div className={styles.instructions}>
          <span className={styles.highlight}>
            {intl.formatMessage({
              id: 'pages.go.wallet_connected.congratulations',
              defaultMessage: 'Congratulations!',
            })}
          </span>
          <span className={styles.highlight}>
            {intl.formatMessage({
              id: 'pages.go.wallet_connected.ready',
              defaultMessage: 'You are ready for the main leaderboard!',
            })}
          </span>
          <span className={styles.space}>
            {intl.formatMessage(
              {
                id: 'pages.go.wallet_connected.tasks',
                defaultMessage:
                  "You'll find additional tasks in the desktop version of {venomNetwork}",
              },
              {
                venomNetwork: venomNetworkLink,
              }
            )}
          </span>

          <img
            className={styles.wallet_connected_img}
            src={venomWalletConnectedSvg}
            width={466}
            height={557}
            loading='lazy'
            alt=''
          />
        </div>
      )}

      <CopyInitDataButton />
    </div>
  );
});

export default GoPage;
