import { memo } from 'react';
import { PrizePoolMain } from './main/prize-pool-main';
import { PrizePoolAll } from './all/prize-pool-all';

type Props = {
  variant?: 'all' | 'main';
};

export const PrizePool = memo(({ variant = 'all' }: Props) => {
  if (variant === 'main') {
    return <PrizePoolMain />;
  }

  return <PrizePoolAll />;
});
