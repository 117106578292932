import styles from './main-page-stage-info.module.css';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useMainPageStore } from '../main-page-store-provider';
import { StageStatusBadge } from 'components/common';
import { useCallback, useMemo } from 'react';
import { StageTimer } from 'components/common/stage-timer/stage-timer';

export const MainPageStageInfo = observer(() => {
  const intl = useIntl();
  const mainPageStore = useMainPageStore();

  const currentStage = useMemo(
    () => mainPageStore.currentSelectedStage,
    [mainPageStore.currentSelectedStage]
  );

  const onCurrentStageTimerFinished = useCallback(() => {
    mainPageStore.refresh();
  }, [mainPageStore]);

  if (!currentStage) {
    return null;
  }

  return (
    <div className={styles.main_outer}>
      <div className={styles.main_inner}>
        <div className={styles.header}>
          <div className={styles.title}>
            {intl.formatMessage(
              {
                id: 'page.main_page.stage_title',
                defaultMessage: 'Stage {number}',
              },
              { number: currentStage.stageIndex }
            )}
          </div>

          <StageStatusBadge status={currentStage.status} />
        </div>

        {currentStage.status === 'live' && (
          <StageTimer
            finishDate={currentStage.endDate}
            onFinish={onCurrentStageTimerFinished}
          />
        )}

        {!!currentStage.descriptionHtml && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: currentStage.descriptionHtml,
            }}
          ></div>
        )}
      </div>
    </div>
  );
});
