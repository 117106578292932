import LangEn from 'lang/en.json';

import { useLocalObservable } from 'mobx-react-lite';
import { VenomNetworkApi, getAuthSecurityWorker } from 'api';
import { CONFIG } from 'config';
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { AuthStore, TelegramStore, UserStore, AppStore } from 'stores';

type BaseStores = {
  venomNetworkApi: VenomNetworkApi;
  authStore: AuthStore;
  telegramStore: TelegramStore;
  userStore: UserStore;
  appStore: AppStore;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = memo(
  ({ children }: { children?: ReactNode }) => {
    const venomNetworkApi = useLocalObservable(
      () =>
        new VenomNetworkApi({
          baseURL: CONFIG.api.baseUrl,
          secure: true,
          securityWorker: getAuthSecurityWorker,
        })
    );

    const telegramStore = useLocalObservable(() => new TelegramStore());

    const authStore = useLocalObservable(
      () => new AuthStore(telegramStore, venomNetworkApi)
    );

    const userStore = useLocalObservable(
      () => new UserStore(authStore, venomNetworkApi)
    );

    const appStore = useLocalObservable(
      () => new AppStore(telegramStore, authStore, userStore)
    );

    const stores = useMemo(
      () => ({
        venomNetworkApi,
        authStore,
        telegramStore,
        userStore,
        appStore,
      }),
      [venomNetworkApi, authStore, telegramStore, userStore, appStore]
    );

    useEffect(() => {
      appStore.initApp();
    }, [appStore]);

    return (
      <baseStoreContext.Provider value={stores}>
        <IntlProvider defaultLocale='en' locale='en' messages={LangEn}>
          <BrowserRouter>{children}</BrowserRouter>
        </IntlProvider>
      </baseStoreContext.Provider>
    );
  }
);

export const useBaseStores = () => {
  return useContext(baseStoreContext);
};
