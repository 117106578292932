import { FriendsPageStore } from './friends-page-store';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useBaseStores } from 'providers/base-stores-provider';
import { createContext, useContext, useEffect } from 'react';

const storeContext = createContext<FriendsPageStore>({} as FriendsPageStore);

type Props = {
  children?: React.ReactNode;
};
export const ProvideFriendsPageStore = observer(({ children }: Props) => {
  const { venomNetworkApi } = useBaseStores();

  const store = useLocalObservable(() => new FriendsPageStore(venomNetworkApi));

  useEffect(() => {
    store.init();
  }, [store]);

  if (!store.initialized) {
    return null;
  }

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useFriendsPageStore = () => {
  return useContext(storeContext);
};
