import { Api } from 'api/venom-network';

export * from 'api/venom-network';

export class VenomNetworkApi extends Api<string | undefined> {}

export interface ApiWithSecurityData {
  setSecurityData: (data: string | null | undefined) => void;
}

export const getAuthSecurityWorker = (
  accessToken: string | undefined | null
) =>
  accessToken
    ? {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    : {};
