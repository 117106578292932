import styles from './friends-page-list.module.css';

import { observer } from 'mobx-react-lite';
import { cutAddress, formatExp } from 'utils/format';
import { useIntl } from 'react-intl';
import { useFriendsPageStore } from '../friends-page-store-provider';

export const FriendsPageList = observer(() => {
  const intl = useIntl();
  const store = useFriendsPageStore();

  if (!store.referralsCollection.items) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.list_wrap}>
        <table className={styles.list}>
          <thead>
            <tr className={styles.header}>
              <th>
                {intl.formatMessage({
                  id: 'page.friends.list.header.rank',
                  defaultMessage: 'Level',
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: 'page.friends.list.header.wallet',
                  defaultMessage: 'User ID',
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: 'page.friends.list.header.xp',
                  defaultMessage: 'XP Gained',
                })}
              </th>
            </tr>
          </thead>

          <tbody>
            {store.referralsCollection.items?.map((x) => (
              <tr key={x.id} className={styles.item}>
                <td className={styles.level}>
                  <div>{x.level}</div>
                </td>
                <td>{cutAddress(x.id ?? '')}</td>
                <td>{formatExp(x.exp)}</td>
              </tr>
            ))}

            {store.referralsCollection.items.length === 0 && (
              <tr className={styles.no_items}>
                <td colSpan={3}>
                  {intl.formatMessage({
                    id: 'page.friends.list.empty',
                    defaultMessage: 'No referrals found',
                  })}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {store.referralsCollection.canLoadMore && (
        <button
          className={styles.load_more}
          onClick={() => store.referralsCollection.fetchMore()}
        >
          {intl.formatMessage({
            id: 'common.button.load_more',
            defaultMessage: 'Load more...',
          })}
        </button>
      )}
    </div>
  );
});
