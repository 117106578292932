import styles from './prizes-page-how-it-works.module.css';

import howItWorksRulesPng from 'images/prizes/how-it-works-bg.png';
import step1Png from 'images/prizes/step1.png';
import step2Png from 'images/prizes/step2.png';
import step5Png from 'images/prizes/step5.png';
import step6Png from 'images/prizes/step6.png';

import { CONFIG } from 'config';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getGeneralRules } from './prizes-page-how-it-works-general-rules';
import {
  getCasesShowcaseData,
  getSpecialCasesShowcaseData,
} from './prizes-page-how-it-works-cases-showcase';
import { getCasesPrizeData } from './prizes-page-how-it-works-cases-prize';
import { CaseImage } from 'components/common/case-image/case-image';

export const PrizesPageHowItWorks = memo(() => {
  const intl = useIntl();

  const generalRules = useMemo(() => getGeneralRules(intl), [intl]);

  const casesShowcaseData = useMemo(
    () => ({
      main: getCasesShowcaseData(intl),
      special: getSpecialCasesShowcaseData(intl),
    }),
    [intl]
  );

  const casesPrizeData = useMemo(() => getCasesPrizeData(), []);

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>
        {intl.formatMessage({
          id: 'page.prizes.how_it_works.title',
          defaultMessage: 'How it works?',
        })}
      </h2>

      <div className={styles.general_rules}>
        <div
          className={styles.general_rules_inner}
          style={{ backgroundImage: `url(${howItWorksRulesPng})` }}
        >
          <h3>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.general_rules.title',
              defaultMessage: 'General rules',
            })}
          </h3>

          {generalRules.map((x, i) => (
            <p key={i}>- {x.text}</p>
          ))}
        </div>
      </div>

      <div className={styles.steps}>
        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step1',
              defaultMessage: '1. Follow the quests to earn XP',
            })}
          </h2>

          <img src={step1Png} width={940} height={364} alt='' loading='lazy' />
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step2',
              defaultMessage: '2. XP gives you a place in the Leaderboard',
            })}
          </h2>

          <img src={step2Png} width={1000} height={438} alt='' loading='lazy' />
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step3',
              defaultMessage:
                '3. Based on your placement, you will be awarded with a Venom Case, and maybe even several. You will also have the opportunity to receive additional Venom Cases for special tasks',
            })}
          </h2>

          <div className={styles.step_prizes}>
            {casesPrizeData.map((x) => (
              <div key={x.text} className={styles.step_prizes_item}>
                <span>{x.text}</span>

                <div className={styles.step}>
                  {x.cases.map((caseType, i) => (
                    <CaseImage key={i} type={caseType} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step4',
              defaultMessage: '4. Venom Cases vary in rarity',
            })}
          </h2>

          <div className={styles.step_cases_rarity}>
            {casesShowcaseData.main.map((x) => (
              <div key={x.text}>
                <span>{x.text}</span>

                {x.image}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step4.special',
              defaultMessage:
                'There are special Venom Cases that contain different amounts of prizes',
            })}
          </h2>

          <div className={styles.step_cases_rarity}>
            {casesShowcaseData.special.map((x) => (
              <div key={x.text}>
                <span>{x.text}</span>

                {x.image}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage({
              id: 'page.prizes.how_it_works.steps.step5',
              defaultMessage:
                '5. Depending on the rarity of the Venom Case, you may need a short break after opening it before moving on to the next one',
            })}
          </h2>

          <img src={step5Png} width={1000} height={385} alt='' loading='lazy' />
        </div>

        <div className={styles.step}>
          <h2>
            {intl.formatMessage(
              {
                id: 'page.prizes.how_it_works.steps.step6',
                defaultMessage: '6. Season {season} will have {stages} stages',
              },
              {
                season: CONFIG.season.current,
                stages: CONFIG.season.totalStages,
              }
            )}
          </h2>

          <img src={step6Png} width={940} height={362} alt='' loading='lazy' />
        </div>
      </div>
    </div>
  );
});
