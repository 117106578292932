import styles from './connect-wallet-page.module.css';

import { memo, useCallback } from 'react';
import {
  ProvideConnectWalletPageStore,
  useConnectWalletPageStore,
} from './connect-wallet-page-store-provider';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/common';
import { cutAddress } from 'utils/format';

import connectVenomWalletSvg from 'images/prizes/connect-venom-wallet.svg';
import venomWalletConnectedSvg from 'images/prizes/venom-wallet-connected.svg';

const ConnectWalletPageWithProvider = observer(() => {
  const store = useConnectWalletPageStore();

  const onConnectClick = useCallback(() => {
    store.connect();
  }, [store]);

  const onBackToAppClick = useCallback(() => {
    store.navigateBackToApp();
  }, [store]);

  if (!!store.initState.error) {
    return (
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.error}>{store.initState.error}</div>

          <img
            className={styles.connect_wallet_img}
            src={connectVenomWalletSvg}
            width={486}
            height={480}
            loading='lazy'
            alt=''
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {!!store.bindState.wallet ? (
        <div className={styles.container}>
          <span className={styles.highlight}>Wallet connected</span>
          <span className={styles.wallet}>
            {cutAddress(store.bindState.wallet)}
          </span>

          <img
            className={styles.wallet_connected_img}
            src={venomWalletConnectedSvg}
            width={466}
            height={557}
            loading='lazy'
            alt=''
          />

          <Button onClick={onBackToAppClick}>Back to App</Button>
        </div>
      ) : (
        <div className={styles.container}>
          {!!store.bindState.error && (
            <>
              <span className={styles.error}>{store.bindState.error}</span>

              <img
                className={styles.connect_wallet_img}
                src={connectVenomWalletSvg}
                width={486}
                height={480}
                loading='lazy'
                alt=''
              />
            </>
          )}

          <Button
            onClick={onConnectClick}
            disabled={store.bindState.inProgress}
            showSpinner={store.bindState.inProgress}
          >
            <span>Connect Wallet</span>
          </Button>
        </div>
      )}
    </div>
  );
});

const ConnectWalletPage = memo(() => {
  return (
    <ProvideConnectWalletPageStore>
      <ConnectWalletPageWithProvider />
    </ProvideConnectWalletPageStore>
  );
});

export default ConnectWalletPage;
