import {
  PostLeaderboardResponseElementDto,
  PostSocialLeaderboardResponseElementDto,
  VenomNetworkApi,
} from 'api';
import { CONFIG } from 'config';
import { makeAutoObservable, runInAction } from 'mobx';
import { StageModel, LeaderboardPlayerModel, StageStatsModel } from 'models';
import { Collection, UserStore } from 'stores';

export enum LeaderboardType {
  Main = 'main',
  Social = 'social',
}

export class LeaderboardPageStore {
  constructor(
    private userStore: UserStore,
    private venomNetworkApi: VenomNetworkApi
  ) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private _initialized = false;
  private _leaderboardType = LeaderboardType.Main;

  private _stages: StageModel[] = [];
  private _currentStage: StageModel | null = null;
  private _currentStageStats: StageStatsModel | null = null;

  private _leaderboardCollection = new Collection<LeaderboardPlayerModel>({
    fetchFn: async (params) => {
      const request =
        this._leaderboardType === 'main'
          ? this.venomNetworkApi.info.postLeaderboard({
              pagination: {
                take: params.take,
                skip: params.skip,
              },
              stage: this._currentStage?.stageIndex,
              season: CONFIG.season.current,
              onlyWithNft: false,
            })
          : this.venomNetworkApi.info.postSocialLeaderboard({
              pagination: {
                take: params.take,
                skip: params.skip,
              },
              stage: this._currentStage?.stageIndex,
              season: CONFIG.season.current,
            });

      const result = await request;

      return {
        items: result.data.data.map((x) => new LeaderboardPlayerModel(x)),
      };
    },
    itemsPerPage: 10,
  });

  get leaderboardCollection() {
    return this._leaderboardCollection;
  }

  get stages() {
    return this._stages;
  }

  get currentStage() {
    return this._currentStage;
  }

  get currentStageStats() {
    if (!this._currentStageStats) {
      return null;
    }

    return this.leaderboardType === LeaderboardType.Main
      ? {
          totalUsers: this._currentStageStats.totalUsers,
          totalXp: this._currentStageStats.totalXp,
        }
      : {
          totalUsers: this._currentStageStats.totalSocialUsers,
          totalXp: this._currentStageStats.totalSocialXp,
        };
  }

  get initialized() {
    return this._initialized;
  }

  get showOverall() {
    return this._currentStage == null;
  }

  get leaderboardType() {
    return this._leaderboardType;
  }

  get currentUser() {
    if (!this.userStore.currentUser || !this.userStore.stats) {
      return null;
    }

    return new LeaderboardPlayerModel({
      user: this.userStore.currentUser.uuid,
      wallet: this.userStore.currentUser?.wallet ?? undefined,
      account: this.userStore.currentUser?.account ?? undefined,
      exp:
        this.leaderboardType === LeaderboardType.Main
          ? this.userStore.stats.mainLeaderboard.totalXp.toString()
          : this.userStore.stats.socialLeaderboard.totalXp.toString(),
      rank:
        this.leaderboardType === LeaderboardType.Main
          ? this.userStore.stats.mainLeaderboard.rank
          : this.userStore.stats.socialLeaderboard.rank,
    } as
      | PostLeaderboardResponseElementDto
      | PostSocialLeaderboardResponseElementDto);
  }

  get showCurrentUserSeparately() {
    if (!this.currentUser?.id) {
      return false;
    }

    if (!this.leaderboardCollection.items) {
      return false;
    }

    return !this.leaderboardCollection.items?.find(
      (x) => x.id === this.currentUser!.id
    );
  }

  async init() {
    const seasonInfo = await this.venomNetworkApi.info.getInfo({
      season: CONFIG.season.current,
    });

    const stages = seasonInfo.data.stages.map((x) => new StageModel(x));

    stages.sort((x, y) => (x.stageIndex > y.stageIndex ? 1 : -1));

    const currentStage = stages.length > 0 ? stages[stages.length - 1] : null;

    runInAction(() => {
      this._stages = stages;
    });

    await this.setCurrentStage(currentStage?.id);

    runInAction(() => {
      this._initialized = true;
    });
  }

  async refresh() {
    await this.fetchCurrentStage();
  }

  async setLeaderboardType(type: LeaderboardType) {
    runInAction(() => {
      this.leaderboardCollection.reset();
      this._leaderboardType = type;
    });

    await this.fetchCurrentStage({ clearLeaderboard: true });
  }

  async setCurrentStage(stageId?: number) {
    runInAction(() => {
      this.leaderboardCollection.reset();
      this._currentStage = stageId
        ? this._stages.find((x) => x.id === stageId) ?? null
        : null;
    });

    await this.fetchCurrentStage({ clearLeaderboard: true });
  }

  private async fetchCurrentStage(props?: { clearLeaderboard?: boolean }) {
    if (props?.clearLeaderboard) {
      this.leaderboardCollection.reset();
    }

    const fetchCollectionPromise = this.leaderboardCollection.fetchData();

    const currentStageIndex = this._currentStage?.stageIndex ?? null;

    const statsInfo = await this.venomNetworkApi.info.postStatsGeneral({
      stage: currentStageIndex,
      season: CONFIG.season.current,
      pagination: { skip: 0, take: 10 },
    });

    const currentStageStats = currentStageIndex
      ? statsInfo.data.data.find((x) => x.stage === currentStageIndex)
      : {
          totalSocialUser: statsInfo.data.total.socialUserCount,
          totalUser: statsInfo.data.total.userCount,
          totalXp: statsInfo.data.total.totalXp,
          totalXpSocial: statsInfo.data.total.totalXpSocial,
        };

    runInAction(() => {
      this._currentStageStats = currentStageStats
        ? new StageStatsModel(currentStageStats)
        : null;
    });

    await fetchCollectionPromise;
  }
}
