import {
  PostLeaderboardResponseElementDto,
  PostSocialLeaderboardResponseElementDto,
} from 'api';
import BigNumber from 'bignumber.js';

export class LeaderboardPlayerModel {
  constructor(
    dto:
      | PostLeaderboardResponseElementDto
      | PostSocialLeaderboardResponseElementDto
  ) {
    this.id = dto.user;
    this.exp = BigNumber(dto.exp);
    this.rank = dto.rank;

    if ('wallet' in dto) {
      this.wallet = dto.wallet;
    }

    if ('account' in dto) {
      this.account = dto.account;
    }
  }

  id: string;
  exp: BigNumber;
  rank: number;
  wallet?: string;
  account?: string;
}
